import React from 'react'
import { Switch, Route, Router } from 'react-router-dom'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { history } from './components/history'

import { LayoutPublic } from './components/Layouts'
import { LayoutAdmin } from './components/Layouts'

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/admin" component={LayoutAdmin} />
        <Route component={LayoutPublic} />
      </Switch>
    </Router>
  )
}

export default App
