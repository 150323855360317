import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function Dacryocysthorhinostomie() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }

    return (
        <Container fluid>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                ></Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h1 style={styleAr} className="py-4">
                        <Trans t={t}>La Dacryocysthorhinostomie</Trans>
                    </h1>
                </Col>
            </Row>

            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h4 style={styleAr}>
                        <Trans t={t}>Definition</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La dacryocysthorhinostomie est une
                                    intervention indiquée principalement en cas
                                    d’obstruction du canal lacrymo-nasal ayant
                                    provoqué une infection du sac lacrymal ou
                                    dacryocystite ou un larmoiement chronique
                                    gênant.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L’intervention consiste à ouvrir une voie
                                    directe entre le sac lacrymal et les fosses
                                    nasales pour évacuer les larmes directement
                                    dans le nez. Pour cela, un morceau d’os est
                                    enlevé entre le sac lacrymal et la cavité
                                    nasale. Le sac lacrymal est directement
                                    cousu à la paroi interne du nez.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La procédure peut se fait par voie externe
                                    sous anesthésie locale dans la majorité des
                                    cas (plus de 90%) avec une petite cicatrice
                                    de 1cm sur la base du nez qui disparaîtra au
                                    bout de quelques semaines.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Cette intervention peut se faire aussi par
                                    voie endonasale (par l’intérieur du nez).
                                    Elle se réalise sous anesthésie générale et
                                    dont l’avantage est l’absence de cicatrice.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Une sonde en silicone est mise en place à
                                    travers les deux canalicules qui sera
                                    retirée après quelques mois.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Dans la majorité des cas, les suites
                                    opératoires sont peu douloureuses. Il peut y
                                    avoir un saignement par la narine, qui
                                    nécessite rarement un méchage.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les soins locaux sont réduits à
                                    l’instillation de collyres, parfois à la
                                    mise en place d’un pansement local.
                                    L’activité professionnelle, l’utilisation
                                    des machines ou instruments dangereux, la
                                    conduite automobile sont déconseillées
                                    pendant une dizaine de jours.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le taux de succès est autours de 94% des
                                    cas.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Les complications</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Un saignement au niveau de la narine
                                    (épistaxis) peut justifier dans des rares
                                    cas, s’il est important, un méchage.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les complications infectieuses locales sont
                                    peu fréquentes et en général, non durables,
                                    mais elles peuvent nécessiter un traitement
                                    par des antibiotiques.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le passage d’air sous la peau peut créer une
                                    sensation de bulles sous-cutanées
                                    (emphysème) il faut s’abstenir de se moucher
                                    pendant la période post-opératoire
                                    immédiate.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L’échec de l’opération peut conduire à une
                                    deuxième chirurgie dans environ 6% des cas
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
