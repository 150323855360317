import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SidebarPhotos from '../SidebarPhotos'
import '../../Procedures/Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function MalpositionPalpébraleEntropion() {
    const { t } = useTranslation()

    return (
        <Container fluid>
            <h1
                style={{ textAlign: 'center', fontSize: '50px' }}
                className="py-4"
            >
                <Trans t={t}>Malposition Palpébrale Entropion</Trans>
            </h1>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <SidebarPhotos />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                ></Col>
            </Row>
        </Container>
    )
}
