import React from 'react'
import i18n from '../../i18n'
import francais from '../../assets/france.jpg'
import english from '../../assets/anglais.jpg'
import arabe from '../../assets/tunis.png'
import Select from 'react-select'

const langage = [
  { value: 'ar', label: arabe },
  { value: 'fr', label: francais },
  { value: 'en', label: english },
]

export default function App() {
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
  }
  let index = langage.findIndex((x: any) => x.value === i18n.language)
  if (index === -1) index = 1

  const formatOptionLabel = ({ label }: any) => {
    return <img alt="la" src={label} style={{ width: '20px' }} />
  }
  return (
    <Select
      options={langage}
      formatOptionLabel={formatOptionLabel}
      defaultValue={langage[index]}
      id="Language"
      onChange={(e: any) => changeLanguage(e.value)}
    />
  )
}
