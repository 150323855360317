import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function Cernes() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }

    return (
        <Container fluid>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                ></Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h1 style={styleAr} className="py-4">
                        <Trans t={t}>Les cernes</Trans>
                    </h1>
                </Col>
            </Row>

            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h4 style={styleAr}>
                        <Trans t={t}>Definition</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    CERNE est une variation de la couleur de la
                                    peau sous les yeux. Il donne au regard un
                                    aspect fatigué et vieilli. IL peut être du à
                                    un creux par un affaissement de la graisse
                                    ou au contraire à une poche et son effet
                                    d’ombre, reflet des poches au dessus plus
                                    marqué par la différence de volume (creux
                                    relatif sous les poches). Aussi il peut être
                                    pigmenté bleu à cause de la peau très fine
                                    et le reflet de la vascularisation au
                                    dessous ou marron du à une concentration
                                    élevée de mélanine.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Plusieurs facteurs peuvent provoquer les
                                    cernes, parmi eux
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <ul>
                                    <li>
                                        <Trans t={t}>Le stress</Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            Le manque de sommeil
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            La consommation excessive d’alcool,
                                            de sel, la mauvaise alimentation
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>La déshydratation</Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            La mauvaise circulation sanguine
                                            dans le visage et autour des yeux
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>Hérédité</Trans>
                                    </li>
                                </ul>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Traitement des cernes</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le traitement des cernes dépend de la cause,
                                    il peut être simple quand il s’agit
                                    d’enlever les poches graisseuses ou de
                                    remplir le creux par injection d’acide
                                    hyaluronique ou des cellules adipocytes
                                    (graisse autologue).
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Il est plus compliqué si il ya d’autres
                                    facteurs associés comme la pigmentation ou
                                    la mauvaise circulation sanguine dont le
                                    traitement est beaucoup plus complexe
                                    associant une bonne hygiène de vie, un
                                    drainage sanguin efficace (sport, massages,
                                    arrêter le tabac) et parfois une chirurgie
                                    endonasale de la déviation septale en cas de
                                    mauvaise respiration.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
