import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function BlepharoplastieInferieure() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }

    return (
        <Container fluid>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                ></Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h1 style={styleAr} className="py-4">
                        <Trans t={t}>Blépharoplastie inférieure</Trans>
                    </h1>
                </Col>
            </Row>

            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les poches, les cernes, l’excès de peau, les
                                    rides, les ridules, la pigmentation et tout
                                    autre changement de l’aspect des paupières
                                    inférieures représentent un motif de
                                    consultation important et de plus en plus
                                    fréquent chez nos patients.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les changements de l’aspect des paupières et
                                    du visage vieilli, fatigué avec l’apparition
                                    des poches ou de creux sont principalement
                                    dus à l’âge, à des facteurs génétiques ou
                                    environnementaux.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les poches sont causées par un prolapsus de
                                    la graisse orbitaire (la graisse autour de
                                    l’œil qui le sépare du cadre osseux
                                    orbitaire) qui est dû au vieillissement des
                                    paupières avec un relâchement du septum qui
                                    représente une membrane séparant l’orbite de
                                    la paupière. Les poches peuvent êtres
                                    présentes aussi chez des patients plus
                                    jeunes génétiquement favorables.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les Cernes peuvent être dus à un creux par
                                    un affaissement de la graisse ou à l’effet
                                    de l’ombre, reflet des poches au dessus plus
                                    marqué par la différence de volume. Ils
                                    peuvent être aussi pigmentés, bleus à cause
                                    de la peau très fine et le reflet de la
                                    vascularisation au dessous, ou marrons dus à
                                    une concentration élevée de mélanine.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le but de l’intervention est d’avoir des
                                    paupières jeunes, naturelles et reposées.
                                    Celle-ci consiste à enlever les poches
                                    graisseuses (associée ou non à une excision
                                    cutanée) mais parfois nous redistribuons ces
                                    poches pour les étaler le long du rebord
                                    périorbitaire (palpébrale inférieure). Chez
                                    d’autres patients nous injectons des
                                    cellules adipocytes pour combler les creux
                                    et retrouver une paupière homogène et
                                    équilibrée.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Ces gestes peuvent être associés à un
                                    lifting médio faciale ou à une lipostructure
                                    faciale (injecter des cellules adipocytes ou
                                    de la graisse au niveau du visage).
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Pendant la chirurgie nous pouvons réaliser
                                    un peeling cutané au TCA, un laser
                                    resurfacing, PRP ou autres actes médicaux
                                    pour un meilleur résultat esthétique.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La chirurgie se fait sous AL dans plus de
                                    90% des cas. La voie d’abord peut être
                                    invisible transconjunctivale en passant par
                                    la partie postérieure de la paupière. Dans
                                    d’autres cas, nous réalisons une incision
                                    cutanée sous ciliaire (qui sera cachée par
                                    les cils) et dont la cicatrice disparaîtra
                                    rapidement.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L'incision transconjonctivale (à l'intérieur
                                    de la paupière inférieure) présente des
                                    avantages la cicatrice est cachée et
                                    invisible et l'incision n’a pas besoin de
                                    points de suture, avec une guérison beaucoup
                                    plus rapide.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Dr Ben Saïd est spécialiste des paupières.
                                    Il prend en charge également les patients
                                    qui ont été opérés ailleurs avec un mauvais
                                    résultat esthétique ou fonctionnel, comme la
                                    rétraction de la paupière inférieure, l’œil
                                    rond, les patients avec une mauvaise
                                    cicatrice ou un ectropion (le bord libre de
                                    la paupière est retourné en dehors) et les
                                    patients qui présentent
                                </Trans>{' '}
                                <Trans t={t}>
                                    une lagophtalmie (mauvaise fermeture des
                                    yeux).
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
