import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import courbeThyroide from '../../../assets/courbeThyroide.png'
export default function OeilEtThyroide() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }

    return (
        <Container fluid>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                ></Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h1 style={styleAr} className="py-4">
                        <Trans t={t}>Oeil et thyroide</Trans>
                    </h1>
                </Col>
            </Row>

            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h4 style={styleAr}>
                        <Trans t={t}>Definition</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L’orbitopthie dysthyroidienne (OD) ou
                                    Grave’s disease ou thyroid eye disease
                                    (TED’s) chez les anglo-saxon est une maladie
                                    inflammatoire auto-immune liée à la glande
                                    thyroïde. Elle affecte les structures
                                    orbitaires périoculaires, les muscles, la
                                    graisse et les paupières.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L’OD peut être associée aux différents
                                    troubles de la glande thyroïde, elle est
                                    fréquemment observer chez les patients qui
                                    présentent une hyperthyroïdie, mais aussi
                                    chez les patient avec une hypothyroïdie et
                                    même chez des patient sans anomalie
                                    thyroïdienne.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La maladie thyroïdienne peut provoquer des
                                    multiples symptômes oculaires qui varient
                                    d’un individu à un autre selon sa sévérité =
                                    sensation d'irritation ou de corps
                                    étrangers, une rougeur, un œdème
                                    conjonctival, une diplopie (vision double),
                                    une baisse de vision, une rétraction
                                    palpébrale, une protrusion de l’œil ou
                                    exophtalmie, il peut également y avoir une
                                    diminution des mouvements oculaires, une
                                    fermeture incomplète de l'œil rarement une
                                    ulcération cornéenne ou une compression du
                                    nerf optique ou une baisse de l’acuité
                                    visuelle.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Comprendre la maladie</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L’orbitopathie dysthyroidienne passe par
                                    deux phases, une phase active et une phase
                                    stable ou la maladie ne progresse pas.
                                    Durant la phase active il y’a un processus
                                    inflammatoire immunitaire qui va provoquer
                                    l’apparition des signes cliniques et surtout
                                    l’aggravation de la maladie. Le but de
                                    traitement est de raccourcir dans le temps
                                    la phase active de l’OD et de diminuer la
                                    sévérité de la maladie. (Voir les courbes ci
                                    dessous)
                                </Trans>
                            </p>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <img
                                    src={courbeThyroide}
                                    alt="courbe sévérité"
                                />
                            </div>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Comment faire le diagnostic ?</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L’orbitopathie dysthyroidienne (OD) est
                                    généralement associée à d’autres symptômes
                                    de l’hyperthyroïdie cependant les symptômes
                                    clinique d’une thyroïde hyperactive ne sont
                                    pas toujours présents. L’OD peut survenir
                                    avant même le déséquilibre de la thyroïde.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L’ophtalmologiste peut diagnostiquer un
                                    déséquilibre thyroïdien en découvrant des
                                    signes clinique en faveur de l’OD.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Une tomodensitométrie (TDM) ou une imagerie
                                    par résonance magnétique (IRM) sont
                                    indiquées pour évaluer les muscles
                                    oculomoteurs et la graisse péri oculaire et
                                    d’autres signes d’inflammation.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L’OD s'améliore souvent spontanément.
                                    Cependant, chez certains patients, les
                                    symptômes peuvent persister malgré le
                                    traitement de la glande thyroïde hyperactive
                                    et de l’OD.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Prévention</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Devant un déséquilibre thyroïdien, la
                                    présence des signes d’orbitopathie
                                    dysthyroidienne (OD) n’est pas systématique,
                                    leurs apparition est imprévisible. On ne
                                    peut pas éviter ou prévenir les signes d’OD.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le traitement de l’hyperthyroïdie par l'iode
                                    radioactif est susceptible d'aggraver l’OD.
                                    Son usage est discuté devant des signes
                                    oculaires. Il doit être évité, si possible
                                    chez les patients avec une atteinte oculaire
                                    sévère. La prise de corticoïde (prédnisone)
                                    au moment du traitement sur plusieurs
                                    semaines peut prévenir l’aggravation de
                                    l’OD.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le traitement avec les antithyroïdiens ou la
                                    chirurgie d’ablation de la thyroïde
                                    n'affecte pas l'évolution de l’orbitopathie
                                    dysthyroidienne.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les tabagiques sont susceptibles de
                                    développer une OD, provoquer une aggravation
                                    et une durée plus importante de la maladie
                                    que les non tabagique. Le tabagisme passif
                                    est aussi dangereux que le tabagisme actif
                                    et doit être évité.
                                </Trans>
                            </p>
                            <p
                                className="paraProcedure"
                                style={
                                    i18n.language === 'ar'
                                        ? {
                                              textAlign: 'right',
                                              color: 'red',
                                              fontSize: '14px',
                                          }
                                        : { color: 'red', fontSize: '14px' }
                                }
                            >
                                <Trans t={t}>
                                    Arrêter le tabac est indispensable pour
                                    éviter les formes résistantes au traitement
                                    ainsi que les formes graves.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Le traitement</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Si vous souffrez de signes oculaires
                                    d’orbitopathie dysthyroidienne, certains
                                    gestes et traitements peuvent être
                                    bénéfiques et soulager les patients.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h6 style={styleAr}>
                        <Trans t={t}>
                            Prévenir des facteurs de risques de l’orbitopathie
                            dysthyroidienne
                        </Trans>
                    </h6>
                    <Row>
                        <Col>
                            <ul style={styleAr}>
                                <li>
                                    <Trans t={t}>le tabac</Trans>
                                    <p
                                        className="paraProcedure"
                                        style={styleAr}
                                    >
                                        <Trans t={t}>
                                            Les tabagiques présentent plus de
                                            formes sévères que les non
                                            tabagiques Les tabagiques répondent
                                            tardivement et moins bien aux
                                            médicaments Arrêter de fumer réduit
                                            les risques de développer une
                                            diplopie (vision double) ou une
                                            exophtalmie (l’œil qui avance ou qui
                                            sort de sa place)
                                        </Trans>
                                    </p>
                                </li>
                                <li>
                                    <Trans t={t}>L’équilibre hormonal</Trans>
                                    <p
                                        className="paraProcedure"
                                        style={styleAr}
                                    >
                                        <Trans t={t}>
                                            Restaurer l’équilibre hormonal par
                                            les Antithyroïdien de synthèse
                                            améliore l’OD. Le traitement de la
                                            thyroïde par l’Iode radioactif peut
                                            provoquer l’apparition ou la
                                            détérioration de l’OD surtout chez
                                            les fumeurs ++++. La thyroïdectomie
                                            (enlever la thyroïde) n’affecte pas
                                            l’évolution de l’OD. L’apparition
                                            des signes cliniques d’une OD peut
                                            être associée à une hypothyroïdie
                                            non contrôlée.
                                        </Trans>
                                    </p>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <h6 style={styleAr}>
                        <Trans t={t}>Manger du sélénium</Trans>
                    </h6>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    C’est un antioxydant et anti-inflammatoire
                                    qui joue un rôle dans l’activité de la
                                    glande thyroïde et du système immunitaire.
                                    Il améliore aussi la qualité de la vie et
                                    les signes cliniques de l’OD.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h6 style={styleAr}>
                        <Trans t={t}>Portez des lunettes de soleil</Trans>
                    </h6>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les yeux sont plus vulnérables aux rayons
                                    ultraviolets et plus sensibles au soleil. Le
                                    port de lunettes de soleil permet de les
                                    protéger du soleil et du vent.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h6 style={styleAr}>
                        <Trans t={t}>Utilisez des collyres lubrifiants</Trans>
                    </h6>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les larmes artificielles, peuvent aider à
                                    soulager la sécheresse et les picotements.
                                    Un gel lubrifiant peut être utilisé avant le
                                    coucher pour protéger la cornée contre
                                    l’environnement et la sécheresse surtout
                                    devant
                                </Trans>
                                <Trans t={t}>
                                    {' '}
                                    une lagophtalmie ou impossibilité de
                                    fermeture totale des paupières pendant le
                                    sommeil.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h6 style={styleAr}>
                        <Trans t={t}>Élevez la tête de votre lit</Trans>
                    </h6>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Garder la tête plus haute que le reste de
                                    votre corps peut diminuer l’œdème et aider à
                                    soulager la pression des yeux.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h6 style={styleAr}>
                        <Trans t={t}>Prismes</Trans>
                    </h6>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Devant une diplopie (vision double), des
                                    lunettes contenant des prismes peuvent être
                                    prescrites par votre médecin. Cependant,
                                    dans quelques cas le prisme n’a pas sa place
                                    et l’indication d’une intervention
                                    chirurgicale sur les muscles extra oculaires
                                    serait plus efficace.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h6 style={styleAr}>
                        <Trans t={t}>Traitement médical</Trans>
                    </h6>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <strong>
                                    <Trans t={t}>
                                        Les glucocorticoïdes. (Prédnisone)
                                    </Trans>
                                </strong>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Joue un rôle important dans la phase active
                                    de la maladie pour améliorer ou arrêter la
                                    progression de la maladie.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les glucocorticoïdes par voie intra veineuse
                                    ont une efficacité de 70 à 80%.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les glucocorticoïdes par voie orale ont une
                                    efficacité moindre entre 50 et 65%.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Des schémas thérapeutiques bien conduits
                                    peuvent être posés pour cibler
                                    l’inflammation et traiter l’OD.
                                </Trans>
                            </p>

                            <p className="paraProcedure" style={styleAr}>
                                <strong>
                                    <Trans t={t}>
                                        Les traitements spécifiques
                                    </Trans>
                                </strong>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Sont utilisés en cas de contre indication ou
                                    la non réponse aux corticoïdes. C’est des
                                    médicaments qui ciblent différent niveau de
                                    la chaine de réaction immunitaire et dont
                                    l’efficacité n’est pas toujours la règle.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La recherche avance rapidement pour
                                    découvrir des nouvelles molécules plus
                                    efficaces. Ce sont des médicaments qui
                                    coutent chers et ne sont pas toujours
                                    disponibles.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h6 style={styleAr}>
                        <Trans t={t}>La Radiothérapie</Trans>
                    </h6>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Peut être d’un apport très utile pour la
                                    prise en charge de l’OD. Elle potentialise
                                    l’effet des corticoïdes et améliore la
                                    motilité oculaire et la diplopie.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h6 style={styleAr}>
                        <Trans t={t}>Chirurgie des paupières</Trans>
                    </h6>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L’OD peut provoquer une rétraction
                                    palpébrale et les yeux devient largement
                                    ouverts. Certains patients peuvent avoir des
                                    difficultés à fermer leurs paupières, ceci
                                    peut rendre les yeux et la cornée plus
                                    exposés ce qui provoque une sécheresse ainsi
                                    qu’une gène oculaire excessives.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le repositionnement chirurgical des
                                    paupières se fait généralement après 3 mois
                                    de stabilité et d’équilibre hormonal, ceci
                                    permet une diminution des signes
                                    fonctionnels et une amélioration de l’aspect
                                    esthétique du patient.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h6 style={styleAr}>
                        <Trans t={t}>Chirurgie musculaire des yeux</Trans>
                    </h6>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Dans l’OD, les muscles peuvent être touchés
                                    par la maladie, subissent une inflammation
                                    avec un œdème et une augmentation de leurs
                                    volumes, ceci est variable d’un muscle à
                                    l’autre. Au fur et à mesure de la
                                    stabilisation de la maladie, une fibrose
                                    musculaire s’installe en faveur d’un aspect
                                    cicatriciel. Ceci peut affecter la motilité
                                    et surtout la synchronisation des mouvements
                                    des deux yeux. On peut avoir une diplopie
                                    (vision double) ou un strabisme.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Devant une diplopie constante, un prisme
                                    peut améliorer la gène. Devant un strabisme
                                    qui n’évolué pas, (à un stade cicatriciel)
                                    une chirurgie musculaire s’impose.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h6 style={styleAr}>
                        <Trans t={t}>
                            Chirurgie de décompression orbitaire
                        </Trans>
                    </h6>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Elle consiste à augmenter le volume
                                    orbitaire en enlevant les parois qui
                                    limitent l’orbite avec les sinus autour. On
                                    peut enlever la paroi interne, la paroi
                                    inférieure (plancher de l’orbite) et la
                                    paroi externe. Dr Ben said réalise la
                                    décompression en utilisant des incisions
                                    cachées qui ne se voient pas.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Cette chirurgie ne peut être réalisée qu’en
                                    cas de stabilité complète de la maladie avec
                                    un bilan thyroïdien équilibré pendant les 3
                                    derniers mois. Elle se fait en premier avant
                                    la chirurgie musculaire et palpébrale.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Cette chirurgie qui augmente le volume de
                                    l’orbite peut être associée à une excision
                                    de la graisse péri oculaire donnant ainsi
                                    plus d’espace à l’œil pour reculer.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La décompression est indiquée dans la
                                    majorité des cas dans un but esthétique pour
                                    récupérer son regard et améliorer les
                                    symptômes cliniques. Dans des rares cas et
                                    devant une compression du nerf optique avec
                                    un risque de perte de l’acuité visuelle qui
                                    est due à une augmentation volumétrique
                                    postérieure des muscles, la décompression
                                    doit se faire en urgence pour libérer le
                                    nerf optique et éviter la perte de l’ AV..
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
