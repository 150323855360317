import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function MalpositionPalpébraleEntropion() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }

    return (
        <Container fluid>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                ></Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h1 style={styleAr} className="py-4">
                        <Trans t={t}>Malposition Palpébrale Entropion</Trans>
                    </h1>
                </Col>
            </Row>

            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h4 style={styleAr}>
                        <Trans t={t}>Definition</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le bord de la paupière peut être retourné
                                    vers l'intérieur en contact de l'œil
                                    (entropion palpébral) ou vers l'extérieur
                                    loin de l'œil (ectropion palpébral).
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Que ce soit entropion ou ectropion, il peut
                                    y avoir des symptômes très variés un
                                    inconfort, une sensation de corps étranger,
                                    une irritation, un larmoiement, une
                                    apparence indésirable,
                                </Trans>{' '}
                                <Trans t={t}>
                                    une lagophtalmie (une impossibilité à la
                                    fermeture palpébrale totale) et dans les cas
                                    avancés une infection ou même une baisse de
                                    vision.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Dans la majorité des cas l’entropion ou
                                    l’ectropion sont dus à une hyper laxité
                                    palpébrale avec un relâchement des tendons
                                    latéraux qui maintiennent la paupière de
                                    part et d’autre. Ils peuvent être d’origine
                                    congénitale, d’origine cicatricielle suite à
                                    un traumatisme, à une brulure ou à une
                                    pathologie cutanée, d’origine mécanique
                                    suite à une tumeur, ou d’origine
                                    paralytique.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La paupière inférieure peut être comparée à
                                    un hamac ou à un filet de tennis. Le filet
                                    de tennis se relâche avec le temps, il peut
                                    chuter ou se plisser. De même, lorsque le
                                    tendon de la paupière inférieur se relâche
                                    avec le temps en raison du vieillissement ou
                                    d'autres causes, la paupière peut s’inverser
                                    (entropion) ou s'éverser (ectropion).
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Entropion des paupières</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L’entropion signifie que le bord palpébral
                                    est retourné vers l'intérieur, les cils
                                    frottent contre le globe oculaire. Il
                                    survient le plus souvent dans les paupières
                                    inférieures rarement les paupières
                                    supérieures. il existe plusieurs étiologies
                                    pour l'entropion celui dû à l’âge est le
                                    plus fréquent, il peut être post
                                    traumatique, ou post chirurgical après une
                                    infection, et rarement congénital.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Symptômes</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les cils frottent sur le globe oculaire,
                                    provoquant une irritation, une rougeur et
                                    une sensation de corps étranger. La cornée
                                    va souffrir du frottement, qui peut induire
                                    des complications de gravité variable
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Des rayures, une ulcération et dans les cas
                                    graves une infection ou un abcès de cornée.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Il est important qu'un chirurgien
                                    spécialiste des paupières répare l’entropion
                                    pour éviter les complications.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>
                            Comment réparer l’entropion palpébral ?
                        </Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le traitement de l’entropion est
                                    principalement chirurgical et la technique
                                    utilisée dépend de l’étiologie. La chirurgie
                                    se fait dans la majorité des cas sous
                                    anesthésie locale. Elle consiste à remettre
                                    définitivement le bord palpébral dans sa
                                    position normale en réparant la cause qui a
                                    provoqué l’entropion.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Parfois et pour des patients spécifiques un
                                    traitement provisoire par des injections de
                                    toxine botuliniques peut améliorer
                                    l’entropion pendant une durée bien
                                    déterminée.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les cas proposés ci-dessous nous permettent
                                    de mieux comprendre les techniques
                                    opératoires utilisées.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
