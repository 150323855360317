import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInstagram,
  faFacebookF,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import './FooterUp.css'

import Menu from './MenuFooter'

function FooterUp() {
  const { t } = useTranslation()
  return (
    <Row className="foot">
      {/* <Col xs={12} md={2} style={{ display: "flex", justifyContent: "center" }}>
        <a href="/" style={{ marginLeft: "30px" }}>
          <img src={logowhite} alt="" className="logof" />
        </a>
      </Col> */}
      <Col xs={12} md={3} className="paraf" style={{ paddingLeft: '40px' }}>
        <p style={{ color: 'white' }}>
          <Trans t={t}>Follow us on Social Media</Trans> <br />
          <a href="http://www.facebook.com">
            <FontAwesomeIcon icon={faFacebookF} style={{ color: 'white' }} />
          </a>
          <a href="http://www.youtube.com">
            <FontAwesomeIcon icon={faYoutube} style={{ color: 'white' }} />
          </a>
          <a href="http://www.instagram.com">
            <FontAwesomeIcon icon={faInstagram} style={{ color: 'white' }} />
          </a>
        </p>
      </Col>
      <Col
        className="contact"
        xs={{ order: 3 }}
        md={{ span: 7, order: 2 }}
        style={{ marginBottom: 'auto' }}
      >
        <p>
          26 avenue de la république Tunis | +216 12 123 123
          <br />
          9:00 – 17:00 |
          <hr style={{ borderColor: 'white' }} />
          <Menu />
        </p>
      </Col>
    </Row>
  )
}
export default FooterUp
