import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function Botox() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }

    return (
        <Container fluid>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                ></Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h1 style={styleAr} className="py-4">
                        <Trans t={t}>Botox</Trans>
                    </h1>
                </Col>
            </Row>

            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h4 style={styleAr}>
                        <Trans t={t}>Historique</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    C'est en 1950 que le Dr Vernon Brooks
                                    propose pour la première fois l'utilisation
                                    médicale de la toxine botulique (Botox) dans
                                    le but de réduire les états
                                    d'hypercontractilité musculaire, mais ce
                                    n'est qu'en 1980 que l'ophtalmologue Alan
                                    Scott utilise cette toxine chez l'homme pour
                                    le traitement du strabisme.4,5 En 1989,
                                    l'utilisation de la toxine botulique de type
                                    A est définitivement acceptée par la FDA
                                    dans le traitement du blépharospasme, du
                                    strabisme et du spasme de l'hémiface. Le
                                    produit est commercialisé sous le nom de
                                    Botox® la même année. En 1991, une autre
                                    préparation de toxine botulique de type A
                                    est approuvée en Angleterre et
                                    commercialisée sous le nom de Dysport®.
                                </Trans>
                                <Trans t={t}>
                                    {' '}
                                    Ces deux produits sont équivalents dans
                                    leurs indications, mais le nombre d'unités
                                    de toxine botulique par flacon n'est pas le
                                    même (un flacon de Botox® équivaut à 100 U,
                                    un flacon de Dysport® équivaut à 500 U). En
                                    raison de la composition du produit
                                    injectable, le conditionnement n'est pas le
                                    même. Botox® se conserve au congélateur à
                                    une température d'environ ¬5°C, alors que
                                    Dysport® peut se conserver à une température
                                    de 2-8°C. Ces produits se présentent sous la
                                    forme de cristaux qu'il faut diluer dans de
                                    la solution physiologique (1-10 ml/100 U
                                    selon les indications et selon les auteurs).
                                    La quantité de toxine diluée dépend
                                    essentiellement des indications, à titre
                                    d'exemple, la dose utilisée dans le
                                    traitement de la spasticité est à peu près
                                    dix fois plus importante que celle utilisée
                                    en médecine esthétique, avec un risque de
                                    résistance immunologique plus important (cf.
                                    effets indésirables).
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Mode d'action</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L'influx nerveux provoque la libération
                                    d'acétylcholine au niveau de la jonction
                                    neuromusculaire qui va entraîner une
                                    contraction musculaire.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La toxine botulique en inhibant la
                                    libération de l'acétylcholine dans la fente
                                    synaptique, empêche la contraction
                                    musculaire. Les jonctions musculaires
                                    affectées sont inhibées. La fibre musculaire
                                    se comporte comme s'il y avait dénervation.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Avant la reprise du fonctionnement
                                    synaptique (synthèse de nouvelles
                                    molécules), il se produit un phénomène de
                                    remodelage synaptique. En effet, dès les
                                    premiers jours suivant l'injection de toxine
                                    botulique, il se produit un bourgeonnement
                                    de nouvelles terminaisons nerveuses à partir
                                    de l'axone terminal qui rétablit ainsi un
                                    contact avec le muscle comme c'est le cas
                                    dans les muscles réinnervés après lésion de
                                    l'axone. Ce processus explique l'effet
                                    réversible d'une injection de toxine
                                    botulique. En général, la fonction
                                    musculaire revient à la normale au fur et à
                                    mesure après quatre à cinq mois.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Indication</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Depuis la première application thérapeutique
                                    en 1980 dans le strabisme, la toxine
                                    botulique a été utilisée dans le traitement
                                    symptomatique de nombreuses pathologies
                                    caractérisées par une hyperactivité de
                                    certains groupes musculaires ou un
                                    dérèglement du système nerveux autonome.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Elle est couramment utilisée pour traiter
                                    des patients atteints de dystonies focales
                                    (torticolis spasmodique, blépharospasme,
                                    crampe de l'écrivain, crampe du musicien,
                                    autres dystonies des membres, dysphonies
                                    spasmodiques, dystonies oro-mandibulaires,
                                    tremblement du chef, luxation récidivante de
                                    la mandibule), spasme hémifacial et
                                    postparalytique. Son utilisation permet
                                    également de réduire la spasticité
                                    (infirmité motrice cérébrale, accidents
                                    vasculaires cérébraux, sclérose en plaques).
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Son indication est aussi établie dans des
                                    indications diverses comme les dyssynergies
                                    vésico-sphinctériennes, l'achalasie, les
                                    spasmes du sphincter d'Ody, les fissures
                                    anales, l'hyperhidrose localisée, les larmes
                                    de crocodile et d'autres indications, telles
                                    la fibromyalgie ou les céphalées de tension.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L'injection de toxine botulique dans les
                                    sites d'hyperhidrose a considérablement
                                    amélioré la qualité de vie de ces patients.
                                    En 1996, Bushara et coll. ont été les
                                    premiers à suggérer l'indication possible de
                                    la toxine botulique dans le traitement de
                                    l'hyperhidrose. Ils ont en effet noté que
                                    les patients traités pour un torticolis
                                    spasmodique avaient une diminution de la
                                    sueur dans la zone traitée.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La toxine botulique diminue la sudation en
                                    inhibant la contraction des cellules
                                    myoépithéliales responsables de la vidange
                                    des glandes sudoripares. Ces cellules sont
                                    contrôlées par le système sympathique à
                                    médiation cholinergique.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le traitement par toxine botulique est
                                    indiqué dans l'hypersudation d'origine
                                    fonctionnelle.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L'utilisation la plus médiatique concerne
                                    actuellement le traitement des rides
                                    d’expression au niveau de la partie
                                    supérieure de la face. En effet le muscle
                                    frontal, le muscle procerus, le muscle
                                    corrugateur et le muscle orbiculaire sont
                                    des muscles peaucier (muscle collé à la
                                    peau). Quand le muscle se contracte, la peau
                                    dessus le suit et une ride se marque. Il
                                    suffit de bloquer les muscles pour que les
                                    rides disparaissent. Les premiers résultats
                                    apparaissent 48 heures après l'injection et
                                    sont complets après dix jours. La
                                    contraction reprend au fur et à mesure à
                                    partir du 4ème mois.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Effets indésirables</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les effets indésirables sont variables selon
                                    les indications. Dans le cas du strabisme,
                                    la paralysie provoquée d'un ou de plusieurs
                                    muscles extra-oculaires peut entraîner une
                                    désorientation spatiale, une vision double,
                                    une ptose. Lorsqu'elle est utilisée pour le
                                    blépharospasme, la toxine peut entraîner une
                                    ptose, une irritation et un larmoiement dû à
                                    la sécheresse de l'œil ou à la photophobie.
                                    Pour la dystonie cervicale, l'effet
                                    indésirable le plus sérieux est le trouble
                                    de la déglutition.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La ptose palpébrale (ptosis) est l’effet
                                    secondaire le plus important et le plus
                                    fréquent pour traiter les rides. Il est noté
                                    en cas d’atteinte du muscle releveur de la
                                    paupière supérieure par diffusion de la
                                    toxine botulique.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Contre indications</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L'administration de la toxine botulique est
                                    contre-indiquée lors de la présence d'une
                                    affection au site d'injection, lors d'une
                                    maladie neurologique périphérique ou d'un
                                    trouble de la jonction neuromusculaire comme
                                    une myasthénie grave. L'utilisation de la
                                    toxine est également contre-indiquée chez
                                    les femmes enceintes.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La toxine botulique, plus connue sous le nom
                                    de « Botox » permet de traiter les rides
                                    d’expression (rides du lion, pattes d’oie,
                                    rides horizontales du front), ainsi que les
                                    problèmes de transpiration excessive. Il
                                    peut se combiner avec d’autres méthodes de
                                    rajeunissement comme le peeling, le laser ou
                                    les produits de comblement.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Les rides</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les rides d’expression sont les premières à
                                    apparaître sur le visage. Elles naissent des
                                    mouvements de notre visage quand nous
                                    manifestons nos émotions.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <ul>
                                    <li>
                                        <Trans t={t}>
                                            Les rides horizontales du front
                                            ressortent lorsque nous écarquillons
                                            les yeux en signe de surprise.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            La ride du lion entre les deux
                                            sourcils apparaît quand on fronce
                                            les sourcils pour réfléchir.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            Les pattes d'oie se forment au coin
                                            des yeux lorsque nous sourions ou
                                            rions.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            Les fines rides verticales strient
                                            les lèvres, la bouche étant une
                                            région particulièrement mobile.
                                        </Trans>
                                    </li>
                                </ul>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Chez les personnes jeunes, la peau retrouve
                                    son aspect lisse après chaque contraction
                                    musculaire. Mais à partir de la trentaine,
                                    la peau devient moins élastique et elle
                                    finit par garder la marque de petits sillons
                                    lorsqu’elle est déformée. Ces sillons vont
                                    ensuite progressivement se creuser et rester
                                    visibles de façon permanente.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
