import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function MalformationVasculaireOrbitaire() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }

    return (
        <Container fluid>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                ></Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h1 style={styleAr} className="py-4">
                        <Trans t={t}>Malformation vasculaire orbitaire</Trans>
                    </h1>
                </Col>
            </Row>

            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les malformations vasculaires proprement
                                    dites sont divisées selon leur flux. Celles
                                    à bas débit sont veineuses (comme les
                                    varices orbitaires), capillaro-veineuses ou
                                    lymphatiques (lymphangiomes). Les
                                    malformations à haut débit, plus rares, sont
                                    artério-veineuses ou artérielles
                                    (anévrisme).
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La prise en charge dépend du type de la
                                    lésion, de sa nature et de son siège. Le
                                    traitement peut être médical, chirurgical et
                                    parfois d’autres techniques
                                    interventionnelles comme l’embolisation
                                    (obstruer un vaisseau en injectant un embole
                                    synthétique) ou la sclérothérpie (un
                                    médicament est injecté dans la lésion pour
                                    la faire rétrécir).
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
