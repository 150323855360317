import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SidebarPhotos from '../SidebarPhotos'
import '../media.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import blephptosisAvant from '../../../assets/blephptosisAvant.jpg'
import blephptosisApres from '../../../assets/blephptosisApres.jpg'
import blephretractAvant from '../../../assets/blephretractAvant.jpg'
import blephretractApres from '../../../assets/blephretractApres.jpg'
import blephSAvant1 from '../../../assets/blephSAvant1.jpg'
import blephSApres1 from '../../../assets/blephSApres1.jpg'
import blephSAvant2 from '../../../assets/blephSAvant2.jpg'
import blephSApres2 from '../../../assets/blephSApres2.jpg'
import blephS3 from '../../../assets/blephS3.jpg'
export default function BlepharoplastieSuperieureM() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar'
            ? { textAlign: 'right' }
            : { textAlign: 'center', paddingTop: '20px' }

    return (
        <Container fluid>
            <h1
                style={{ textAlign: 'center', fontSize: '50px' }}
                className="py-4"
            >
                <Trans t={t}>blépharoplastie supérieure</Trans>
            </h1>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <SidebarPhotos />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <Row>
                        <Col className="colMedia">
                            <img
                                src={blephptosisAvant}
                                alt="blépharoplastie supérieure cas 1 avant"
                                className="imgMedia"
                            />
                            <img
                                src={blephptosisApres}
                                alt="blépharoplastie supérieure cas 1 apres"
                                className="imgMedia"
                            />
                        </Col>
                        <Col
                            style={{
                                backgroundColor: '#FAFAFA',
                            }}
                        >
                            <h4 style={styleAr}>
                                <Trans t={t}>Blépharoplastie ptosis</Trans>
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="colMedia">
                            <img
                                src={blephretractAvant}
                                alt="blépharoplastie supérieure cas 2 avant"
                                className="imgMedia"
                            />
                            <img
                                src={blephretractApres}
                                alt="blépharoplastie supérieure cas 2 apres"
                                className="imgMedia"
                            />
                        </Col>
                        <Col>
                            <h4 style={styleAr}>
                                <Trans t={t}>Blépharoplastie retract</Trans>
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="colMedia">
                            <img
                                src={blephSAvant1}
                                alt="blépharoplastie supérieure cas 3 avant"
                                className="imgMedia"
                            />
                            <img
                                src={blephSApres1}
                                alt="blépharoplastie supérieure cas 3 apres"
                                className="imgMedia"
                            />
                        </Col>
                        <Col
                            style={{
                                backgroundColor: '#FAFAFA',
                            }}
                        >
                            <h4 style={styleAr}>
                                <Trans t={t}>
                                    Blépharoplastie supérieure 1er cas
                                </Trans>
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="colMedia">
                            <img
                                src={blephSAvant2}
                                alt="blépharoplastie supérieure cas 4 avant"
                                className="imgMedia"
                            />
                            <img
                                src={blephSApres2}
                                alt="blépharoplastie supérieure cas 4 apres"
                                className="imgMedia"
                            />
                        </Col>
                        <Col>
                            <h4 style={styleAr}>
                                <Trans t={t}>
                                    Blépharoplastie supérieure 2eme cas
                                </Trans>
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="colMedia">
                            <img
                                src={blephS3}
                                alt="blépharoplastie supérieure cas 5 avant"
                                className="imgMedia"
                            />
                        </Col>
                        <Col
                            style={{
                                backgroundColor: '#FAFAFA',
                            }}
                        >
                            <h4 style={styleAr}>
                                <Trans t={t}>
                                    Blépharoplastie supérieure 3eme cas
                                </Trans>
                            </h4>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
