import React from 'react'
import { history } from '../history'
import { Row, Col } from 'react-bootstrap'
import { useTranslation, Trans } from 'react-i18next'
import './Home.css'

export default function Specializes() {
  const { t } = useTranslation()
  return (
    <div>
      <Row className="mb-3 mt-5">
        <Col className="title">
          <h2 style={{ fontSize: '34px' }}>
            <Trans t={t}>Doctor Ahmed ben said Specializes</Trans>
          </h2>
        </Col>
      </Row>
      <Row className="cardSection" style={{ justifyContent: 'center' }}>
        {/* <CardB
          img={cosmetic1}
          title={t("Chirurgie palpébrale et lacrymale")}
          href="/Procedures/eyelid-and-lacrimal-surgery"
        />

        <CardB
          img={orbital1}
          title={t("Chirurgie orbitaire et cavité")}
          href="/Procedures/orbital-and-cavity-surgery"
        />
        <CardB
          img={eyelid1}
          title={t("Chirurgie esthétique et injections")}
          href="/Procedures/cosmetic-surgery-and-injections"
        /> */}

        <Col
          className="specializies"
          onClick={() =>
            history.push('/procedures/cosmetic-surgery-and-injections')
          }
        >
          <p className="pSpecializies" style={{ color: 'white' }}>
            <Trans t={t}>Chirurgie esthétique et injections</Trans>
          </p>
        </Col>

        <Col
          className="specializies1"
          onClick={() => history.push('/procedures/orbital-and-cavity-surgery')}
        >
          <p className="pSpecializies">
            <Trans t={t}>Chirurgie orbitaire et cavité</Trans>
          </p>
        </Col>

        <Col
          className="specializies2"
          onClick={() =>
            history.push('/procedures/eyelid-and-lacrimal-surgery')
          }
        >
          <p className="pSpecializies">
            <Trans t={t}>Chirurgie palpébrale et lacrymale</Trans>
          </p>
        </Col>
      </Row>
    </div>
  )
}
