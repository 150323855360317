import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

export default function Menu() {
  const { t } = useTranslation()
  return (
    <nav className="d-flex  flex-wrap menuFooter">
      <a href="/" className="a px-4">
        <Trans t={t}>Home</Trans>
      </a>
      <a href="/about" className="a px-4">
        <Trans t={t}>About</Trans>
      </a>
      <a href="/procedure" className="a px-4">
        <Trans t={t}>Procedures</Trans>
      </a>
      <a href="/media" className="a px-4">
        <Trans t={t}>Media</Trans>
      </a>
      <a href="/contact" className="a px-4">
        <Trans t={t}>Contact</Trans>
      </a>
    </nav>
  )
}
