import React, { useState } from 'react'
import './Contact.css'
import Iframe from 'react-iframe'
import { Row, Col, Form, Button, Container } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../i18n'

export default function Contact() {
    const { t } = useTranslation()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')

    const sendSubmit = () => {
        console.log('name ' + name)
        console.log('email ' + email)
        console.log('phone ' + phone)
        console.log('message ' + message)
    }

    return (
        <Container>
            <Row>
                <Col
                    style={{ textAlign: 'center', fontFamily: 'Elsie' }}
                    className="mt-4 mb-3"
                >
                    <h1 style={{ fontSize: '50px' }}>
                        <Trans t={t}>Contact Us</Trans>
                    </h1>
                </Col>
            </Row>
            <Row
                className="contact"
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse', textAlign: 'right' }
                        : {}
                }
            >
                <Col xs={12} md={6}>
                    <Row
                        style={
                            i18n.language === 'ar'
                                ? { flexDirection: 'row-reverse' }
                                : {}
                        }
                    >
                        <Col md={3}>
                            <p className="label">
                                <Trans t={t}>Phone Number</Trans>
                            </p>
                        </Col>
                        <Col md={6}>
                            <span style={{ color: '#898989' }}>
                                {' '}
                                +216 12 123 123
                            </span>
                        </Col>
                    </Row>
                    <Row
                        style={
                            i18n.language === 'ar'
                                ? { flexDirection: 'row-reverse' }
                                : {}
                        }
                    >
                        <Col md={3}>
                            <p className="label mt-3 mt-sm-0">
                                <Trans t={t}>Email</Trans>
                            </p>
                        </Col>
                        <Col md={6}>
                            <span style={{ color: '#898989' }}>
                                {' '}
                                contact@drbensaid.com
                            </span>
                        </Col>
                    </Row>
                    <Row
                        style={
                            i18n.language === 'ar'
                                ? { flexDirection: 'row-reverse' }
                                : {}
                        }
                    >
                        <Col md={3}>
                            <p className="label mt-3 mt-sm-0">
                                <Trans t={t}>Address</Trans>
                            </p>
                        </Col>
                        <Col md={6}>
                            <span style={{ color: '#898989' }}>
                                <Trans t={t}> tunis, tunis</Trans>
                            </span>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={6}>
                    <Form>
                        <Form.Group
                            as={Row}
                            controlId="exampleForm.ControlInput1"
                            style={
                                i18n.language === 'ar'
                                    ? { flexDirection: 'row-reverse' }
                                    : {}
                            }
                        >
                            <Form.Label
                                column
                                sm="3"
                                className="label pt-4 pt-sm-1 ml-2 ml-sm-0"
                            >
                                <Trans t={t}>Name</Trans>
                            </Form.Label>
                            <Col sm="9">
                                <Form.Control
                                    name="name"
                                    value={name}
                                    onChange={(e: any) => {
                                        setName(e.target.value)
                                    }}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group
                            as={Row}
                            controlId="exampleForm.ControlInput1"
                            style={
                                i18n.language === 'ar'
                                    ? { flexDirection: 'row-reverse' }
                                    : {}
                            }
                        >
                            <Form.Label
                                column
                                sm="3"
                                className="label ml-2 ml-sm-0"
                            >
                                <Trans t={t}>Email</Trans>
                            </Form.Label>
                            <Col sm="9" style={{ margin: 'auto' }}>
                                <Form.Control
                                    name="email"
                                    value={email}
                                    onChange={(e: any) => {
                                        setEmail(e.target.value)
                                    }}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group
                            as={Row}
                            controlId="exampleForm.ControlInput1"
                            style={
                                i18n.language === 'ar'
                                    ? { flexDirection: 'row-reverse' }
                                    : {}
                            }
                        >
                            <Form.Label
                                column
                                sm="3"
                                className="label ml-2 ml-sm-0"
                            >
                                <Trans t={t}>Phone Number</Trans>
                            </Form.Label>
                            <Col sm="9" style={{ margin: 'auto' }}>
                                <Form.Control
                                    name="Phone"
                                    value={phone}
                                    onChange={(e: any) => {
                                        setPhone(e.target.value)
                                    }}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group
                            as={Row}
                            controlId="exampleForm.ControlTextarea1"
                            style={
                                i18n.language === 'ar'
                                    ? { flexDirection: 'row-reverse' }
                                    : {}
                            }
                        >
                            <Form.Label
                                column
                                sm="3"
                                className="label ml-2 ml-sm-0"
                            >
                                <Trans t={t}>Message</Trans>
                            </Form.Label>
                            <Col sm="9" style={{ margin: 'auto' }}>
                                <Form.Control
                                    as="textarea"
                                    rows={6}
                                    name="message"
                                    value={message}
                                    onChange={(e: any) => {
                                        setMessage(e.target.value)
                                    }}
                                />
                            </Col>
                        </Form.Group>
                        <Col style={{ textAlign: 'center' }}>
                            <Button
                                name="Send"
                                onClick={sendSubmit}
                                style={{
                                    backgroundColor: '#47D1F3',
                                    border: 'none',
                                }}
                            >
                                <Trans t={t}>Send</Trans>
                            </Button>
                        </Col>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Iframe
                        url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12771.033298999922!2d10.141088927172738!3d36.84826365009795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd334710df602b%3A0x4e0cbd9362acd8e!2sEl%20Menzah%209%2C%20Ariana!5e0!3m2!1sfr!2stn!4v1597836838194!5m2!1sfr!2stn"
                        width="100%"
                        height="450"
                        styles={{ border: '0' }}
                    />
                </Col>
            </Row>
        </Container>
    )
}
