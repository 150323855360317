import React, { useState, useEffect } from 'react'
import { Accordion, Card, Button } from 'react-bootstrap'
import '../Procedures/Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../i18n'
import right from '../../assets/right.png'
export default function Sidebar() {
    const { t } = useTranslation()
    const path = window.location.pathname.split('/')
    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const [open2, setOpen2] = useState(false)
    useEffect(() => {
        if (path[2]) {
            setOpen(path[2] === 'eyelid-and-lacrimal-surgery')
            setOpen1(path[2] === 'orbital-and-cavity-surgery')
            setOpen2(path[2] === 'cosmetic-surgery-and-injections')
        }
    }, [path])

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : {}

    return (
        <Accordion
            defaultActiveKey={
                !path[2]
                    ? '-1'
                    : path[2] === 'eyelid-and-lacrimal-surgery'
                    ? '0'
                    : path[2] === 'orbital-and-cavity-surgery'
                    ? '1'
                    : '2'
            }
        >
            <Card style={{ border: 'none' }}>
                <Card.Header style={styleAr}>
                    <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey="0"
                        onClick={(e: any) => {
                            if (!open) {
                                setOpen(!open)
                                setOpen1(false)
                                setOpen2(false)
                            }
                            setOpen(!open)
                        }}
                    >
                        <div
                            style={
                                i18n.language === 'ar'
                                    ? {
                                          flexDirection: 'row-reverse',
                                          display: 'flex',
                                      }
                                    : {}
                            }
                        >
                            <img
                                src={right}
                                className={
                                    open
                                        ? 'fa fa-arrow-down open'
                                        : 'fa fa-arrow-down'
                                }
                                alt="right"
                                style={{ width: '16px', marginRight: '5px' }}
                            />
                            <Trans t={t}>
                                Chirurgie palpébrale et lacrymale
                            </Trans>
                        </div>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body style={{ paddingBottom: 'unset' }}>
                        <ul>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/eyelid-and-lacrimal-surgery/ectropion"
                                        className="aProcedure"
                                        style={
                                            path[3] === 'ectropion'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>Ectropion</Trans>
                                    </a>
                                </p>
                            </li>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/eyelid-and-lacrimal-surgery/ptosis"
                                        className="aProcedure"
                                        style={
                                            path[3] === 'ptosis'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>Ptosis</Trans>
                                    </a>
                                </p>
                            </li>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/eyelid-and-lacrimal-surgery/tumeurs-palpebrales"
                                        className="aProcedure"
                                        style={
                                            path[3] === 'tumeurs-palpebrales'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>Tumeurs palpebrales</Trans>
                                    </a>
                                </p>
                            </li>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/eyelid-and-lacrimal-surgery/intubation"
                                        className="aProcedure"
                                        style={
                                            path[3] === 'intubation'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>Intubation</Trans>
                                    </a>
                                </p>
                            </li>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/eyelid-and-lacrimal-surgery/dacryocysthorhinostomie"
                                        className="aProcedure"
                                        style={
                                            path[3] ===
                                            'dacryocysthorhinostomie'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>
                                            La dacryocysthorhinostomie
                                        </Trans>
                                    </a>
                                </p>
                            </li>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/eyelid-and-lacrimal-surgery/larmoiement"
                                        className="aProcedure"
                                        style={
                                            path[3] === 'larmoiement'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>
                                            Larmoiement des yeux
                                        </Trans>
                                    </a>
                                </p>
                            </li>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/eyelid-and-lacrimal-surgery/syndrome-sec"
                                        className="aProcedure"
                                        style={
                                            path[3] === 'syndrome-sec'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>Le syndrome sec</Trans>
                                    </a>
                                </p>
                            </li>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/eyelid-and-lacrimal-surgery/malposition-palpebrale-entropion"
                                        className="aProcedure"
                                        style={
                                            path[3] ===
                                            'malposition-palpebrale-entropion'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>
                                            Malposition palpébrale entropion
                                        </Trans>
                                    </a>
                                </p>
                            </li>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/eyelid-and-lacrimal-surgery/plaies-des-paupieres-canalicules"
                                        className="aProcedure"
                                        style={
                                            path[3] ===
                                            'plaies-des-paupieres-canalicules'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>
                                            Plaies des paupières canalicules
                                        </Trans>
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card style={{ border: 'none' }}>
                <Card.Header
                    style={
                        i18n.language === 'ar'
                            ? { textAlign: 'right', backgroundColor: 'white' }
                            : { backgroundColor: 'white' }
                    }
                >
                    <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey="1"
                        onClick={(e: any) => {
                            setOpen1(!open1)
                            setOpen(false)
                            setOpen2(false)
                        }}
                    >
                        <div
                            style={
                                i18n.language === 'ar'
                                    ? {
                                          flexDirection: 'row-reverse',
                                          display: 'flex',
                                      }
                                    : {}
                            }
                        >
                            <img
                                src={right}
                                className={
                                    open1
                                        ? 'fa fa-arrow-down open'
                                        : 'fa fa-arrow-down'
                                }
                                alt="right"
                                style={{ width: '16px', marginRight: '5px' }}
                            />
                            <Trans t={t}>Chirurgie orbitaire et cavité</Trans>
                        </div>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body style={{ paddingBottom: 'unset' }}>
                        <ul>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/orbital-and-cavity-surgery/enucleation-evisceration-prothese"
                                        className="aProcedure"
                                        style={
                                            path[3] ===
                                            'enucleation-evisceration-prothese'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>
                                            Enucléation éviscération prothèse
                                        </Trans>
                                    </a>
                                </p>
                            </li>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/orbital-and-cavity-surgery/inflammation-et-infection-orbitaire"
                                        className="aProcedure"
                                        style={
                                            path[3] ===
                                            'inflammation-et-infection-orbitaire'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>
                                            Inflammation et infection orbitaire
                                        </Trans>
                                    </a>
                                </p>
                            </li>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/orbital-and-cavity-surgery/malformation-vasculaire-orbitaire"
                                        className="aProcedure"
                                        style={
                                            path[3] ===
                                            'malformation-vasculaire-orbitaire'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>
                                            Malformation vasculaire orbitaire
                                        </Trans>
                                    </a>
                                </p>
                            </li>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/orbital-and-cavity-surgery/oeil-thyroide-et-traitement"
                                        className="aProcedure"
                                        style={
                                            path[3] ===
                                            'oeil-thyroide-et-traitement'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>
                                            Oeil thyroide et traitement
                                        </Trans>
                                    </a>
                                </p>
                            </li>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/orbital-and-cavity-surgery/tumeur-orbitaire"
                                        className="aProcedure"
                                        style={
                                            path[3] === 'tumeur-orbitaire'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>Tumeur orbitaire</Trans>
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card style={{ border: 'none' }}>
                <Card.Header style={styleAr}>
                    <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey="2"
                        onClick={(e: any) => {
                            setOpen2(!open2)
                            setOpen1(false)
                            setOpen(false)
                        }}
                    >
                        <div
                            style={
                                i18n.language === 'ar'
                                    ? {
                                          flexDirection: 'row-reverse',
                                          display: 'flex',
                                      }
                                    : {}
                            }
                        >
                            <img
                                src={right}
                                className={
                                    open2
                                        ? 'fa fa-arrow-down open'
                                        : 'fa fa-arrow-down'
                                }
                                alt="right"
                                style={{ width: '16px', marginRight: '5px' }}
                            />
                            <Trans t={t}>
                                Chirurgie esthétique et injections
                            </Trans>
                        </div>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>
                        <ul>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/cosmetic-surgery-and-injections/blepharoplastie-inferieure"
                                        className="aProcedure"
                                        style={
                                            path[3] ===
                                            'blepharoplastie-inferieure'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>
                                            Blépharoplastie inférieure
                                        </Trans>
                                    </a>
                                </p>
                            </li>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/cosmetic-surgery-and-injections/blepharoplastie-superieure"
                                        className="aProcedure"
                                        style={
                                            path[3] ===
                                            'blepharoplastie-superieure'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>
                                            Blépharoplastie supérieure
                                        </Trans>
                                    </a>
                                </p>
                            </li>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/cosmetic-surgery-and-injections/botox"
                                        className="aProcedure"
                                        style={
                                            path[3] === 'botox'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>Botox</Trans>
                                    </a>
                                </p>
                            </li>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/cosmetic-surgery-and-injections/cernes"
                                        className="aProcedure"
                                        style={
                                            path[3] === 'cernes'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>Les cernes</Trans>
                                    </a>
                                </p>
                            </li>
                            <li className="liAccordion">
                                <p style={styleAr}>
                                    <a
                                        href="/media/cosmetic-surgery-and-injections/ptosis-et-bleph"
                                        className="aProcedure"
                                        style={
                                            path[3] === 'ptosis-et-bleph'
                                                ? {
                                                      color: '#47d1f3',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Trans t={t}>Ptosis et bleph</Trans>
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}
