import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function TumeurOrbitaire() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }

    return (
        <Container fluid>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                ></Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h1 style={styleAr} className="py-4">
                        <Trans t={t}>Tumeur orbitaire</Trans>
                    </h1>
                </Col>
            </Row>

            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h4 style={styleAr}>
                        <Trans t={t}>Definition de l'orbite</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    C'est une région anatomique située autour du
                                    globe oculaire, délimitée par un cadre
                                    osseux auquel participent de nombreux os.
                                    Elle comprend 7 muscles, la glande
                                    lacrymale, le globe oculaire et son nerf
                                    optique. L'ensemble de ces tissus est
                                    entouré de la graisse orbitaire, elle-même
                                    parcourue par des vaissea ux et des nerfs.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Definition de la tumeur</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    C'est un processus anormal dans sa
                                    localisation et sa nature. Cette tumeur peut
                                    être bénigne ou maligne. La tumeur peut se
                                    développer à partir d’une structure de la
                                    composante orbitaire (os, graisse, muscle,
                                    vaisseaux, nerf), ou secondaires à des
                                    métastases. Certains croissent lentement et
                                    passent inaperçus tandis que d'autres
                                    peuvent se développer rapidement.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Une tumeur maligne est un cancer,
                                    c'est-à-dire une tumeur composée de cellules
                                    se multipliant de façon anarchique,
                                    envahissant et détruisant les structures
                                    avoisinantes. Elle est susceptible
                                    d'essaimer à distance dans d'autres organes,
                                    constituant des métastases. La tumeur
                                    maligne comporte donc un risque vital.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Chez les enfants, les tumeurs orbitaires les
                                    plus fréquentes sont les kystes dermoïdes et
                                    les hémangiomes. Chez l’adulte on trouve les
                                    hémangiomes caverneux, les métastases
                                    orbitaires et les lymphomes orbitaires.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>
                            Les signes cliniques d’une tumeur de l'orbite
                        </Trans>
                    </h4>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <p
                                        className="paraProcedure"
                                        style={styleAr}
                                    >
                                        <Trans t={t}>
                                            Un déplacement du globe oculaire
                                            repoussé selon la position de la
                                            tumeur, une diplopie ou vision
                                            double (interaction de la tumeur
                                            avec les muscles), une baisse
                                            d'acuité visuelle ou altération du
                                            champ visuel (si atteinte du nerf
                                            optique), une rougeur, un
                                            gonflement, une inflammation plus ou
                                            moins douloureuse de la région
                                            orbitaire, des modifications
                                            cutanées.
                                        </Trans>
                                    </p>
                                </li>
                                <li>
                                    <p
                                        className="paraProcedure"
                                        style={styleAr}
                                    >
                                        <Trans t={t}>
                                            La palpation ou la visibilité
                                            directe d'un processus expansif
                                            comblant les creux au-dessus ou en
                                            dessous des paupières, ou entre le
                                            globe oculaire et les paupières.
                                        </Trans>
                                    </p>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>
                            Les examens complémentaires nécessaires au
                            diagnostic
                        </Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Il s'agit essentiellement d'examens
                                    radiologiques scanner (TDM) et/ou imagerie
                                    par résonance magnétique nucléaire (IRM)
                                    sont dans la grande majorité des cas
                                    indispensables.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Une échographie orbitaire peut également
                                    être pratiquée.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Au terme de ces examens, la confrontation de
                                    l'examen clinique et des caractéristiques
                                    radiologiques permettra dans plus de 50% des
                                    cas d'avoir une idée assez précise du
                                    diagnostic.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Ce diagnostic ne sera toutefois confirmé que
                                    par l'analyse directe d'un échantillon
                                    (c'est la biopsie) ou de la totalité de la
                                    tumeur.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Parfois votre ophtalmologiste ne jugera pas
                                    nécessaire d'effectuer cette biopsie. En
                                    effet, si les signes cliniques et
                                    radiologiques sont suffisamment
                                    caractéristiques d'une lésion.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Pourquoi opérer ?</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L'intervention est rendue nécessaire pour
                                    connaître la nature exacte de votre tumeur,
                                    la traiter, et empêcher une extension
                                    régionale et/ou générale.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La chirurgie orbitaire est délicate et doit
                                    être réalisé par un chirurgien
                                    oculoplasticien spécialiste de l’orbite.
                                    Pour comprendre la délicatesse et la
                                    difficulté du geste la tumeur doit être
                                    extirpée de l’orbite qui représente un cône
                                    solide étroit en profondeur et qui fait 30
                                    cm3 avec une structure noble et fragile en
                                    son centre, c'est le globe oculaire (7 cm3).
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Il faut éviter toute blessure irréversible
                                    du nerf optique et de son pédicule
                                    vasculo-nerveux. Toute la difficulté
                                    dépendra donc de la localisation de la
                                    tumeur
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Une tumeur antérieure sera en général
                                    facilement dégagée. L'incision est
                                    habituellement située dans une zone cutanée
                                    camouflable pli palpébral, sourcil…Il existe
                                    également des voies d'abord qui ne sont pas
                                    cutanées mais conjonctivales sans cicatrice
                                    externe visible (la conjonctive est
                                    l'enveloppe muqueuse qui recouvre le globe
                                    oculaire et la face postérieure des
                                    paupières).
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Pour accéder à une tumeur plus postérieure,
                                    c'est-à-dire plus au fond de l'orbite, on
                                    peut réaliser une ostéotomie (enlever une
                                    partie de l’os des parois externe de
                                    l’orbite et la remettre en fin
                                    d’intervention) qui va permettre une bonne
                                    exposition de l’orbite particulièrement la
                                    région externe et derrière l’œil. L’incision
                                    pour cette voie d’abord sera toujours cachée
                                    au niveau du pli palpébral.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Une incision conjonctivale sans cicatrice
                                    peut être aussi réalisée pour des tumeurs
                                    profondes.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L'ensemble des ces interventions se passe
                                    sous anesthésie générale sauf parfois pour
                                    les petites tumeurs antérieures. Le temps
                                    d'hospitalisation est en général assez bref
                                    pour les petites tumeurs antérieures, mais
                                    peut être de quelques jours, deux à trois
                                    jours pour les cas très compliqués.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L’Évolution postopératoire habituelle Après
                                    une phase transitoire d'inflammation et
                                    d'hématome, votre région orbitaire retrouve
                                    un aspect extérieur normal. Les fils cutanés
                                    seront généralement retirés à j7 après
                                    l'intervention.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le diagnostic définitif établi par
                                    l'anatomo-pathologiste se fera après
                                    quelques jours. En cas de tumeur maligne, un
                                    traitement complémentaire après la chirurgie
                                    (radiothérapie et/ou chimiothérapie) sera
                                    discuté en réunion pluridisciplinaire (avec
                                    d’autres médecins spécialistes en
                                    oncologie). Il a pour but de diminuer le
                                    risque de récidive locale de la tumeur, et
                                    le risque de survenue de métastases.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Certaines tumeurs ont une prise en charge
                                    plus spécifique une partie des tumeurs
                                    vasculaires peuvent être traité par un
                                    chirurgien spécialiste des paupières et de
                                    l’orbite qui va chercher la tumeur et
                                    injecter un produit sclérosant (une
                                    sclérothérapie) qui va faire fondre la
                                    tumeur. Dans des rares cas la prise en
                                    charge par un radiologue spécialisé est
                                    nécessaire, Celui-ci embolise la tumeur,
                                    c'est à dire bouche la malformation
                                    vasculaire pour la faire régresser. Pour
                                    cela, le radiologue conduit une sonde par
                                    voie vasculaire pour accéder au site
                                    malformé et y déposer les substances
                                    embolisantes.
                                </Trans>
                            </p>

                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les complications sont rares. Il peut s'agir
                                    soit
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <ul>
                                    <li>
                                        <Trans t={t}>
                                            D'un hématome il faut savoir que le
                                            lendemain de l'intervention les
                                            paupières sont gonflées avec une
                                            part plus ou moins importante
                                            d'inflammation. Tout ceci est en
                                            règle générale transitoire et
                                            régressif. Parfois le saignement
                                            opératoire est plus important et le
                                            chirurgien peut être amené à placer
                                            un drain qui sort à la peau et qui
                                            sera retiré 2 à 3 jours après
                                            l'intervention.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            D'une infection comme toute
                                            chirurgie, la chirurgie orbitaire
                                            peut être à la source d'infections.
                                            Une couverture antibiotique per et
                                            postopératoire est systématiquement
                                            et rend ce type de complication
                                            rare.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            D'une diplopie (vision double) en
                                            raison de la manipulation per
                                            opératoire des muscles oculomoteurs
                                            ; elle est en règle générale
                                            rapidement régressive ; dans le cas
                                            contraire, elle peut être compensée
                                            par des prismes ou par une chirurgie
                                            dans un second temps.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            D'une baisse d'acuité visuelle par
                                            une souffrance du nerf optique par
                                            exemple par un hématome orbitaire
                                            trop important. Une blessure per
                                            opératoire du nerf optique en cas de
                                            tumeur adjacente peut également être
                                            à l'origine de cette baisse d'acuité
                                            visuelle. L'occlusion des vaisseaux
                                            de la rétine est rare mais possible.
                                        </Trans>
                                    </li>
                                </ul>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
