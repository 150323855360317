import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function EnucléationEviscerationProthese() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }

    return (
        <Container fluid>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                ></Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h1 style={styleAr} className="py-4">
                        <Trans t={t}>Enucléation éviscération prothèse</Trans>
                    </h1>
                </Col>
            </Row>

            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h4 style={styleAr}>
                        <Trans t={t}>Definition de l'énucléation</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L'énucléation est la procédure chirurgicale
                                    dans laquelle le globe oculaire est retiré
                                    pour différentes raisons, comme l'œil non
                                    voyant douloureux, un œil traumatisé non
                                    récupérables et les tumeurs oculaires.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <strong>
                                    <Trans t={t}>
                                        La chirurgie d'énucléation
                                    </Trans>
                                </strong>{' '}
                                <Trans t={t}>
                                    se fait en ambulatoire, sous anesthésie
                                    générale dans 80% des cas et sous anesthésie
                                    locale dans 20 % des cas.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Un implant intra orbitaire sphérique
                                    permanent est inséré à la place de l’œil. Il
                                    faut environ 1 mois à 6 semaines pour avoir
                                    une guérison totale. Après la guérison le
                                    patient est référé à un oculariste pour
                                    fabriquer une prothèse oculaire sur mesure
                                    qui ressemble étroitement à l'autre œil
                                    sain, rendant souvent difficile de
                                    différencier entre les deux cotés.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <strong>
                                    <Trans t={t}>
                                        Les ocularistes sont des artistes
                                    </Trans>
                                </strong>{' '}
                                <Trans t={t}>
                                    qui fabriquent des prothèses sur mesure
                                    personnalisées à chaque patient dont la
                                    couleur, l’aspect et la ressemblance se
                                    confond avec l’œil sain.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Definition de l'éviscération</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L'éviscération est une procédure plus
                                    utilisée que l'énucléation, elle consiste à
                                    couvrir l’implant intra orbitaire qui va
                                    remplacer l’œil par la sclère qui est la
                                    couche la plus externe et qui ne sera pas
                                    enlevée.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L'éviscération est préférée à l’énucléation
                                    car elle offre une meilleure motilité
                                    oculaire et réduit les risques d'extrusion
                                    de l’implant. En effet la sclère qui va
                                    couvrir l’implant reste toujours attachée
                                    aux muscles intra oculaires.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L’exentération est une procédure plus
                                    invasive que l'éviscération, et
                                    l’énucléation. en effet tout le contenu de
                                    l'orbite y compris l'œil, est retiré. Cela
                                    se fait généralement pour certaines tumeurs
                                    ou infections fongiques graves.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Causes de la chirurgie</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L’indication de l’éviscération ou de
                                    l'énucléation varie d'un patient à un autre.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les traumatismes oculaires violents, les
                                    globes perdus suite à plusieurs
                                    interventions chirurgicales quand ils sont
                                    douloureux et atrophique, les tumeurs
                                    oculaires, les infections, …
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
