import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function TumeurPalpebrales() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }

    return (
        <Container fluid>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                ></Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h1 style={styleAr} className="py-4">
                        <Trans t={t}>Tumeurs palpébrales</Trans>
                    </h1>
                </Col>
            </Row>

            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h4 style={styleAr}>
                        <Trans t={t}>Definition</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La peau palpébrale est la plus fine du corps
                                    humain avec une exposition importante au
                                    soleil. Cela fait des paupières l’endroit
                                    privilégié pour développer des lésions
                                    cutanées bénignes et cancéreuses. La
                                    majorité des lésions des paupières sont
                                    bénignes et faciles à traiter par simple
                                    excision. Les trois principales lésions sont
                                </Trans>
                                <ul className="paraProcedure" style={styleAr}>
                                    <li>
                                        <Trans t={t}>
                                            Le carcinome basocellulaire qui
                                            provient de la prolifération
                                            anormale de cellules basales de
                                            l’épiderme
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            Le carcinome spinocellulaire
                                            (épidermoïde) qui se forme à partir
                                            des cellules squameuses
                                            (kératinocyte)
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            Les mélanomes qui se développent à
                                            partir des mélanocytes
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            Le carcinome basocellulaire est le
                                            cancer de la peau le plus fréquent.
                                        </Trans>{' '}
                                        <Trans t={t}>
                                            Il est généralement identifié très
                                            précocement et son développement est
                                            lent. Pour le traitement, la
                                            chirurgie est très efficace.
                                        </Trans>
                                    </li>
                                </ul>
                                <p className="paraProcedure" style={styleAr}>
                                    <Trans t={t}>
                                        Chaque année, 65 000 nouveaux cas de
                                        carcinome basocellulaire sont
                                        diagnostiqués en France. L’augmentation
                                        du nombre des cas est relative à
                                        l’accroissement de l’espérance de vie
                                        ainsi qu’à des comportements à risque
                                        liés principalement à l’exposition au
                                        soleil. Ils touchent surtout les
                                        personnes de plus de 50 ans et sont plus
                                        fréquents chez les hommes que chez les
                                        femmes.
                                    </Trans>
                                </p>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Les facteurs de risque</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Certains facteurs sont fréquemment retrouvés
                                </Trans>
                                <ul style={styleAr}>
                                    <li style={styleAr}>
                                        <Trans t={t}>
                                            L’exposition intense et répétée au
                                            soleil, notamment dès l’enfance
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            Le teint, les cheveux et yeux clairs
                                            souvent indicateurs d’une peau dite
                                            sensible au soleil
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            Les antécédents personnels de cancer
                                            de la peau (récidive)
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            L’exposition à des radiations
                                            ionisantes ou à certaines substances
                                            chimiques telles que l’arsenic, des
                                            dérivés du pétrole combiné aux
                                            ultraviolets (traitement du
                                            psoriasis)
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            L’affaiblissement du système
                                            immunitaire (lié à un traitement
                                            anti rejet de greffe ou une maladie
                                            comme le SIDA)
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            Certains facteurs génétiques
                                        </Trans>
                                    </li>
                                </ul>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Le carcinome basocellulaire</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le carcinome basocellulaire représente 90%
                                    des tumeurs palpébrales, il se développe
                                    lentement et régulièrement au fil des mois
                                    et des années. Il peut prendre l’aspect
                                    d’une perle nacrée, ferme au toucher
                                    présentant souvent une vascularisation et
                                    qui risque de se décomposer et de s’ulcérer.
                                    ç un orgelet nous alarme quand il devient
                                    chronique, devant une perte de cils ou si il
                                    récidive après une excision simple.Il peut
                                    aussi se présenter comme une plaque cutanée
                                    à l’allure écailleuse et désquamante.
                                    Parfois il peut ressembler à une cicatrice
                                    endurcie. L’aspect d’un ulcère ou d’une
                                    lésion qui saigne doit nous alarmer
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le carcinome basocellulaire ne se propage
                                    pas dans des régions éloignées (se
                                    métastaser), par contre il continue à se
                                    développer et à s'infiltrer dans les tissus
                                    avoisinants.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le diagnostic positif est suspecté par la
                                    clinique, appuyé et confirmé par une biopsie
                                    et un examen anatomopathologique.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les carcinomes basocellulaires peuvent être
                                    guéris par une excision complète et totale
                                    de la lésion suivie d'une reconstruction
                                    pour réparer l’endroit endommagé.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Il existe plusieurs techniques pour
                                    reconstruire la paupière, dans la majorité
                                    des cas dans le même temps opératoire que
                                    l’excision (voir photos).
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Le carcinome épidermoide</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le carcinome épidermoïde est moins fréquent
                                    mais plus agressif. Il est caractérisé par
                                    une lésion avec une apparence épaisse,
                                    squameuse et irrégulière. Mais cet aspect
                                    n’est pas toujours la règle. Son diagnostic
                                    peut être posé devant toute lésion qui ne
                                    guérit pas sur une peau endommagé par une
                                    grande exposition au soleil.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le carcinome épidermoïde débute sous la
                                    forme d’une zone rouge à la surface
                                    squameuse et croûteuse. En se développant,
                                    la tumeur peut prendre du relief et durcir.
                                    Parfois, elle ressemble à une verrue. Enfin,
                                    la tumeur cancéreuse évolue en ulcération et
                                    se développe dans les tissus sous-jacents.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le pronostic des petites tumeurs retirées en
                                    entier et suffisamment tôt est excellent. Le
                                    traitement est habituellement efficace et la
                                    plupart des personnes survivent. La grande
                                    majorité des carcinomes épidermoïdes reste
                                    localisée dans leur zone d’apparition,
                                    d’autres s’étendent dans les tissus
                                    environnants et quelques rares cas se
                                    propagent (métastasent) à d’autres zones de
                                    l’organisme.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le diagnostic positif est suspecté par la
                                    clinique, appuyé et confirmé par une biopsie
                                    et un examen anatomopathologique. La
                                    chirurgie reste la solution, elle se fait
                                    selon des règles strictes contrôlée par un
                                    rapport anatomopathologique (microscopie de
                                    Mohs). La radiothérapie peut être indiquée
                                    pour les cas avancés.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Il existe plusieurs techniques pour
                                    reconstruire la paupière, dans la majorité
                                    des cas dans le même temps opératoire que
                                    l’excision Comme le risque de métastase
                                    existe, les médecins contrôlent étroitement
                                    le traitement et le suivi.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Le mélanome palpébral</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Si le mélanome est le moins fréquent des
                                    cancers de la peau, il est aussi le plus
                                    grave. Il doit être détecté dès le début de
                                    son évolution pour une prise en charge
                                    précoce.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Contrairement à une idée répandue, ce n'est
                                    pas parce que l'on présente très peu de
                                    grains de beauté que le risque de mélanome
                                    est moindre. Les trois-quarts des mélanomes
                                    se forment en effet en dehors d'un grain de
                                    beauté préexistant.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le mélanome, est une tumeur maligne des
                                    mélanocytes, les cellules qui fabriquent la
                                    mélanine. une exposition importante au
                                    soleil favorise le dérèglement de ces
                                    cellules.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Autre facteur de risque une exposition aux
                                    rayons UV artificiels des cabines de
                                    bronzage. De nombreux travaux scientifiques
                                    ont démontré le lien entre l'exposition aux
                                    cabines de bronzage, même une seule fois, et
                                    le développement d'un mélanome. Selon le
                                    Centre international de recherche sur le
                                    cancer, le risque de mélanome est augmenté
                                    de 60% pour les personnes ayant effectué une
                                    première exposition aux UV artificiels avant
                                    l’âge de 30 ans.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La règle A B C D, facile à mettre en œuvre,
                                    permet de détecter un changement au niveau
                                    de l’Asymétrie, des Bords, de la Couleur, de
                                    la Dimension ou de l'Evolutivité d'un grain
                                    de beauté.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le mélanome est une forme dangereuse de
                                    cancer de la peau en raison de sa propension
                                    à se propager vers d'autres organes (fort
                                    potentiel métastasique). Détecté tôt, il se
                                    guérit très bien, avec un taux très élevé de
                                    survie. Une bonne raison de se faire
                                    dépister chaque année !
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Prévention</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Étant donné que le carcinome basocellulaire
                                    et épidermoïde peut être causé par
                                    l’exposition au soleil, il peut être évité
                                    grâce aux mesures suivantes, dès la petite
                                    enfance
                                </Trans>
                                <ul className="paraProcedure" style={styleAr}>
                                    <li>
                                        <Trans t={t}>
                                            Éviter le soleil par exemple, se
                                            mettre à l’ombre, limiter les
                                            activités à l’extérieur entre 10 h
                                            et 16 h (lorsque les rayons du
                                            soleil sont les plus puissants) et
                                            éviter de prendre des bains de
                                            soleil et d’utiliser des solariums
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            Porter des vêtements anti-UV par
                                            exemple, des T-shirts à manches
                                            longues, des pantalons et des
                                            chapeaux à large bord
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            Appliquer de l’écran solaire une
                                            protection minimale ayant un indice
                                            de protection solaire de 30 avec une
                                            protection anti Ultraviolet a et
                                            anti Ultraviolet b, réappliquée
                                            toutes les 2 heures et après être
                                            allé dans l’eau ou avoir transpiré,
                                            mais pas utilisée pour prolonger
                                            l’exposition au soleil
                                        </Trans>
                                    </li>
                                </ul>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
