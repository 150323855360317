import axios from "axios";

const LoginAdmin = async (login: string, password: string) => {
  try {
    const response = await axios.post(
      "https://dr-ahmed-ben-said.herokuapp.com/auth/admin",
      {
        login: login,
        password: password,
      }
    );
    return response.data;
  } catch (error) {
    return "error";
  }
};

export { LoginAdmin };
