import React, { useState } from "react";
import { Row, Col, Form, Button, Container, Modal } from "react-bootstrap";

import { Add, Show } from "../../api/media";
import ShowImg from "./ShowImg";

interface Props {
  showAjout: boolean;
  setData(data: any): any;
  closeHandler(): void;
}

export default function ModelAdd(props: Props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [img, setImg] = useState("");

  const submitForm = async () => {
    await Add(title, description, img);
    const data = await Show();
    props.setData(data);
    props.closeHandler();
    setImg("");
  };
  return (
    <Modal show={props.showAjout} onHide={props.closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 style={{ marginLeft: "100px" }}>Upload Media</h2>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container
          fluid
          style={{
            justifyContent: "center",
          }}
        >
          <Col xs={12} md={12} style={{ margin: "20px" }}>
            <Form>
              <Form.Group as={Row} controlId="exampleForm.ControlInput1">
                <Form.Label
                  column
                  sm="2"
                  className="label pt-4 pt-sm-1 ml-2 ml-sm-0"
                >
                  Title
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="title"
                    // value={title}
                    onChange={(e: any) => {
                      setTitle(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label column sm="2" className="label ml-2 ml-sm-0">
                  Message
                </Form.Label>
                <Col sm="10" style={{ margin: "auto" }}>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    name="description"
                    // value={description}
                    onChange={(e: any) => {
                      setDescription(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
              <Col>
                <ShowImg img={img} />
                <Form.Group>
                  <Form.File
                    id="exampleFormControlFile1"
                    label="Upload your media"
                    onChange={(e: any) => {
                      setImg(e.target.files[0]);
                    }}
                  />
                </Form.Group>
              </Col>
            </Form>
          </Col>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.closeHandler}>
          Close
        </Button>
        <Button
          variant="primary"
          style={{
            backgroundColor: "#4FEA9E",
            border: "none",
            padding: "7px 20px",
          }}
          onClick={submitForm}
          disabled={!description || !title}
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
