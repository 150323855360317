import React, { useState } from 'react'
import { Nav, Dropdown } from 'react-bootstrap'
import { useTranslation, Trans } from 'react-i18next'
import i18n from '../../i18n'
import ChangeLangage from './ChangeLangage'

function Menu() {
  const [showProcedures, setShowProcedures] = useState(false)
  const { t } = useTranslation()

  return (
    <Nav
      className="ml-auto"
      style={i18n.language === 'ar' ? { flexDirection: 'row-reverse' } : {}}
    >
      <Nav.Link href="/" className="dropTitle">
        <div>
          <Trans t={t}>Home</Trans>
        </div>
      </Nav.Link>
      <Nav.Link href="/about" className="dropTitle">
        <div>
          <Trans t={t}>About</Trans>
        </div>
      </Nav.Link>
      <Dropdown
        style={{ padding: '0 15px' }}
        show={showProcedures}
        onMouseEnter={(e: any) => {
          setShowProcedures(true)
        }}
        onMouseLeave={(e: any) => {
          setShowProcedures(false)
        }}
      >
        <Dropdown.Toggle
          href="/procedures"
          id="dropdown-basic"
          className="dropTitle pro"
        >
          <Trans t={t}>Procedures</Trans>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropMenu">
          <Dropdown.Item
            href="/procedures/eyelid-and-lacrimal-surgery"
            className="dropItem "
            style={{ borderBottom: '1px solid #2d3e4f' }}
          >
            <Trans t={t}>Chirurgie palpébrale et lacrymale</Trans>
          </Dropdown.Item>
          <Dropdown.Item
            href="/procedures/orbital-and-cavity-surgery"
            className="dropItem"
            style={{ borderBottom: '1px solid #2d3e4f' }}
          >
            <Trans t={t}>Chirurgie orbitaire et cavité</Trans>
          </Dropdown.Item>
          <Dropdown.Item
            href="/procedures/cosmetic-surgery-and-injections"
            className="dropItem"
          >
            <Trans t={t}>Chirurgie esthétique et injections</Trans>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Nav.Link href="/media" className="dropTitle">
        <div>
          <Trans t={t}>Photo Gallery</Trans>
        </div>
      </Nav.Link>

      <Nav.Link href="/contact" className="dropTitle">
        <div>
          <Trans t={t}>Contact</Trans>
        </div>
      </Nav.Link>
      <ChangeLangage />
    </Nav>
  )
}

export default Menu
