import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import oeil from '../../../assets/oeil.png'
export default function Intubation() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }

    return (
        <Container fluid>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                ></Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h1 style={styleAr} className="py-4">
                        <Trans t={t}>Intubation</Trans>
                    </h1>
                </Col>
            </Row>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Vous êtes atteint d’un larmoiement et/ou
                                    d’un gonflement de l’angle interne de l’œil,
                                    et/ou d’une surinfection des voies
                                    lacrymales.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Ceci peut être dû à une obstruction au
                                    niveau des voies lacrymales.L’intubation se
                                    définit par la mise en place au niveau du
                                    système de drainage lacrymal d’un tube pour
                                    garder le canalicule ou le canal
                                    lacrymo-nasal ouvert et lever l’obstruction.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L’indication de cette intervention dépend de
                                    l’âge du patient, du niveau de
                                    l’obstruction, de l’état de la région et du
                                    système de drainage lacrymal.
                                </Trans>
                            </p>

                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Votre ophtalmologiste vous propose
                                    l’opération, car la chirurgie constitue le
                                    seul moyen d’améliorer vos troubles. Cette
                                    fiche contient une information détaillée sur
                                    l’opération qui vous est proposée, sur ses
                                    résultats et sur ses risques.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L’intervention Lors d’obstacle sur les voies
                                    lacrymales ou lors d’inflammation chronique
                                    de ces voies lacrymales, consiste à mettre
                                    en place une sonde en silicone pour
                                    maintenir les voies lacrymales ouvertes. Ces
                                    voies lacrymales comportent deux canalicules
                                    situés au niveau des paupières, un sac au
                                    niveau de l’angle interne de l’œil et un
                                    canal qui descend au niveau du nez (canal
                                    lacrymo-nasal).
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Types d'intubation</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Il existe plusieurs types d'intubation
                                </Trans>
                                <ul style={{ marginTop: '8px' }}>
                                    <li
                                        className="paraProcedure"
                                        style={styleAr}
                                    >
                                        <strong>
                                            <Trans t={t}>
                                                Une intubation
                                                bi-canaliculo-nasale
                                            </Trans>
                                        </strong>
                                        <Trans t={t}>
                                            {' '}
                                            qui permet le passage de la sonde
                                            par les deux canalicules supérieure
                                            et inférieure. Deux brins de cette
                                            sonde en silicone vont être utilisés
                                            passant chacun dans un des deux
                                            canalicules. Les deux bouts de la
                                            sondes seront nouées entre elles et
                                            éventuellement fixées au niveau du
                                            nez par un fil. Une petite boucle de
                                            silicone sera visible dans l’angle
                                            interne de l’œil, entre les deux
                                            points lacrymaux, supérieur et
                                            inférieur.
                                        </Trans>
                                    </li>
                                    <li
                                        className="paraProcedure"
                                        style={styleAr}
                                    >
                                        <strong>
                                            <Trans t={t}>
                                                Une intubation
                                                mono-canaliculo-nasale
                                            </Trans>
                                        </strong>
                                        <Trans t={t}>
                                            {' '}
                                            qui permet le passage par un seul
                                            canalicule palpébral. Un seul brin
                                            de silicone va être mis en place
                                            dans un canalicule, principalement
                                            l’inférieure jusqu’au niveau du nez.
                                            Le le fil sera simplement sectionné
                                            au niveau du nez. Une petite
                                            collerette en silicone sera visible
                                            au niveau du bord de la paupière qui
                                            aura été intubée.
                                        </Trans>
                                    </li>
                                </ul>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L’intubation se réalise sous anesthésie
                                    générale. L’intervention est relativement
                                    rapide d’une durée en moyenne inférieure à 5
                                    minutes. Une épistaxis de petite abondance
                                    peut se voir pendant et quelques heures
                                    après l’intubation, et disparaitra
                                    rapidement La mise en place de la sonde va
                                    permettre de garder les voies lacrymales
                                    ouvertes. Du fait du diamètre de la sonde un
                                    larmoiement peut persister après sa mise en
                                    place. La durée de conservation des sondes
                                    varie de 4 à 6 mois. L’ablation de la sonde
                                    se fait facilement au cabinet sans
                                    anesthésie.
                                </Trans>
                            </p>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <img src={oeil} alt="oeil" />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
