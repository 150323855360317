import React from 'react'
import './Header.css'
import { Col, Row, Navbar } from 'react-bootstrap'
import { history } from '../history'

import ContactInformation from './ContactInformation'
import Menu from './Menu'
import ContactMobile from './ContactMobile'

import logo from '../../assets/logo.png'

export default function HeaderUser() {
  return (
    <Row style={{ margin: 'unset' }}>
      <Col xs="12">
        <ContactMobile />
      </Col>
      <Col xs="12">
        <Navbar expand="lg" style={{ width: '100%' }}>
          <Row style={{ margin: 'unset' }}>
            <Col style={{ padding: 'unset' }}>
              <Navbar.Brand
                href="/"
                style={{
                  maxWidth: '250px',
                  minWidth: '150px',
                  padding: '8px 0 0 15px',
                }}
              >
                <img
                  src={logo}
                  style={{ width: '100%' }}
                  alt="logo"
                  onClick={() => {
                    history.push('/')
                  }}
                />
              </Navbar.Brand>
            </Col>
            <Col
              style={{
                padding: 'unset',
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
              </div>
            </Col>
            <Col style={{ flexGrow: 0, padding: 'unset' }}>
              <Row style={{ margin: 'unset' }}>
                <Col className="bardesktop" xs="12">
                  <ContactInformation />
                </Col>
                <Col>
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Menu />
                  </Navbar.Collapse>
                </Col>
              </Row>
            </Col>
          </Row>
        </Navbar>
      </Col>
    </Row>
  )
}
