import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import FooterUp from './FooterUp'
import FooterInst from './FooterInst'

function Footer() {
  return (
    <Container fluid style={{ marginTop: '0px' }}>
      <FooterInst />
      <FooterUp />

      <Row
        style={{
          backgroundColor: '#47D1F3',
          textAlign: 'center',
          fontSize: '12px',
          padding: '15px',
          color: 'white',
          marginBottom: '0px',
        }}
      >
        <Col>
          <p>
            Dr. Ben Said Oculoplastic Surgeon Tunisia, Tunis. | 2020 All Rights
            Reserved.
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer
