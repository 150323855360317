import axios from "axios";

const Update = async (
  title: string,
  description: string,
  img: string,
  id: any
) => {
  const media = new FormData();
  media.append("title", title);
  media.append("description", description);
  if (img !== "") media.append("img", img);
  const key = sessionStorage.getItem("admin-token");
  const options = {
    headers: {
      "auth-token": key,
    },
  };
  const response = await axios.put(
    "https://dr-ahmed-ben-said.herokuapp.com/media/" + id,
    media,
    options
  );
  return response.data;
};

export default Update;
