import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from './Sidebar'
import procedure from '../../assets/procedure.jpg'
import './Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../i18n'
export default function Procedures() {
    const { t } = useTranslation()
    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : {}

    return (
        <Container fluid>
            <h1
                style={{
                    textAlign: 'center',
                    marginBottom: '40px',
                    fontFamily: 'Elsie',
                }}
                className="py-4"
            >
                <Trans t={t}>Procedures</Trans>
            </h1>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col md={{ span: 9, order: 2 }} xs={{ span: 12, order: 1 }}>
                    <p style={styleAr}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Tempore nam facere temporibus sint eveniet error quae
                        assumenda voluptas, incidunt quis excepturi repellat
                        obcaecati quidem perferendis deserunt, id similique quo
                        quibusdam
                    </p>
                    <img
                        src={procedure}
                        alt="procedure"
                        style={{ width: '100%' }}
                    />
                </Col>
            </Row>
        </Container>
    )
}
