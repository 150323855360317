import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function Eyelid() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : {}

    return (
        <Container fluid style={{ padding: '0 5%' }}>
            <h1 style={{ textAlign: 'center' }} className="py-4">
                <Trans t={t}>Chirurgie palpébrale et lacrymale</Trans>
            </h1>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            L’œil est un organe complexe et sensible qui
                            necessite une protection particulière et adaptée.
                        </Trans>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            La paupière couvre et protège la cornée , partie
                            antérieure de l’œil. Elle est mobile et composée de
                            plusieurs plans et tissus peau, muscle, graisse,
                            cartilage et conjonctive.
                        </Trans>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            La pathologie palpébrale est très variée. C’est
                            ainsi qu’on peut rencontrer des tumeurs de paupières
                            (qu’elles soient bénignes ou malignes), des troubles
                            de la statique palpébrale (ectropion, entropion) ou
                            de la dynamique palpébrale comme les rétractions ou
                            les ptosis, sans compter les trichiasis ou encore
                            des plaies traumatiques comme il existe également de
                            nombreuses pathologies congénitales malformatives.
                        </Trans>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            Toutes ces affections demandent des traitements
                            spécifiques. Elles seront essentiellement traitées
                            chirurgicalement par résection, repositionnement ou
                            plastie reconstructrice.
                        </Trans>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            L'appareil lacrymal est un ensemble complexe qui est
                            composé de deux systèmes. Le premier est sécréteur
                            il comprend la glande lacrymale principale qui est à
                            l’origine de la sécrétion des larmes reflexes, et
                            les glandes lacrymales accessoires, qui élaborent le
                            film lacrymal permettant de recouvrir et nourrir la
                            cornée. Le deuxième est excréteur il comprend les
                            points lacrymaux, petits orifices situés sur le bord
                            libre des paupières, à l'extrémité interne de
                            celles-ci, et les canalicules lacrymaux qui leur
                            font suite et se dirigent en dedans, vers le sac
                            lacrymal, situé entre l'angle interne de l'œil et la
                            paroi nasale et relié à la cavité nasale par le
                            canal lacrymo-nasal.
                        </Trans>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            L’oculoplasticien connait le mieux l’anatomie des
                            paupières, ainsi que celle des voies lacrymales et
                            toutes les techniques chirurgicales spécifiques à la
                            réparation et à la reconstruction des anomalies
                            liées à l’ensemble des structures palpébrales et
                            lacrymales. Il est ainsi formé pour la meilleure
                            prise en charge.
                        </Trans>
                    </p>
                </Col>
            </Row>
        </Container>
    )
}
