import React from 'react'
import { Carousel } from 'react-bootstrap'
import { useTranslation, Trans } from 'react-i18next'

export default function CarouselB() {
  const { t } = useTranslation()
  return (
    <Carousel indicators={false} nextIcon={false} prevIcon={false}>
      <Carousel.Item className="carouselItem">
        <div style={{ marginLeft: '15%' }}>
          <h2 style={{ fontFamily: 'Elsie', color: 'white' }}>First Slide</h2>
          <h2 style={{ color: '#2d3e4f', fontFamily: 'Elsie' }}>
            Best eyes health
          </h2>
          <h3
            style={{
              color: '#2D3E4F',
              fontFamily: ' Raleway',
              fontSize: '20px',
            }}
          >
            Nulla vitae elit libero, a pharetra augue mollis interdum.
          </h3>
          <button className="button" style={{ background: '#2d3e4f' }}>
            <Trans t={t}>Learn More</Trans>
          </button>
        </div>
      </Carousel.Item>
    </Carousel>
  )
}
