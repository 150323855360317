import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../i18n'
import about1 from '../../assets/about1.jpeg'
import aboutDR from '../../assets/aboutDR.jpeg'
import './About.css'
export default function About() {
  const { t } = useTranslation()
  const styleAr: React.CSSProperties =
    i18n.language === 'ar'
      ? { textAlign: 'right', marginTop: '15px' }
      : { textAlign: 'justify', marginTop: '15px' }
  return (
    <Container fluid>
      <Row>
        <Col style={{ marginBottom: '2rem', marginTop: '2rem' }}>
          <h1
            style={{
              textAlign: 'center',
              fontFamily: 'Elsie',
              fontSize: '50px',
            }}
          >
            <Trans t={t}>About dr Ben Said Ahmed</Trans>
          </h1>
        </Col>
      </Row>
      <Row style={{ backgroundColor: '#fafafa', padding: '2rem 2rem' }}>
        <Col
          md={3}
          xs={
            i18n.language === 'ar'
              ? { span: 12, order: 2 }
              : { span: 12, order: 1 }
          }
          className="text-center text-md-left"
        >
          <img
            src={about1}
            alt="about dr ben said ahmed"
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '10px',
              objectFit: 'cover',
              objectPosition: '50% 50%',
              boxShadow: ' 0px 2px 8px  rgba(0, 0, 0, 0.40)',
            }}
          />
        </Col>
        <Col
          md={9}
          xs={
            i18n.language === 'ar'
              ? { span: 12, order: 1 }
              : { span: 12, order: 2 }
          }
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <h3
            style={
              i18n.language === 'ar'
                ? { textAlign: 'right', width: '100%' }
                : { textAlign: 'left', width: '100%' }
            }
            className="mt-3 mt-sm-0"
          >
            <Trans t={t}>Dr.Ahmed Ben Said</Trans>
          </h3>
          <p style={styleAr}>
            <Trans t={t}>
              Dr Ben Said est ophtalmologiste diplômé de la faculté de médecine
              de Tunis. Il a poursuivi sa formation en France à Paris de 2001 à
              2005 à la fondation ophtalmologique A de Rothschild et à l’hôpital
              saint joseph. Il est passé par plusieurs services prestigieux à
              savoir celui du Pr Than Huong Xuan spécialisé dans la cataracte,
              le segment antérieure et la chirurgie réfractive (Fondation A de
              Rothschild), le service du Dr Y Lachkar à l’institut de glaucome
              (hôpital saint joseph) et le service du Dr Serge Morax, spécialisé
              dans la chirurgie palpébrale, lacrymale et orbitaire (Fondation A
              de Rothschild).
            </Trans>
          </p>
          {/* <p style={styleAr}>
            <Trans t={t}>Dr Ben Said est diplômé</Trans>
          </p>
          <p style={styleAr}>
            <Trans t={t}>
              En inflammation et infection oculaire en 2003 de la faculté de
              médecine Xavier Bichat- université Paris 7, Denis Diderot.
            </Trans>
          </p>
          <p style={styleAr}>
            <Trans t={t}>
              En chirurgie et pathologie orbito-palpébro-lacrymale en 2007 de la
              faculté de médecine de Nice, université de Nice-Sophia Antipolis.
            </Trans>
          </p>
          <p style={styleAr}>
            <Trans t={t}>
              De retour à Tunis il a intégré les ophtalmologistes associés de
              Tunis et la clinique ophtalmologique de Tunis, cité médicale Ibn
              Zohr, Route X2, Cité El Khadra-Tunis.
            </Trans>
          </p>
          <p style={styleAr}>
            <Trans t={t}>
              Dr Ben Said participe et réalise des travaux scientifiques à
              l’échelle nationale et internationale et continue à perfectionner
              sa formation pour les techniques de pointe partout dans le monde
              et notamment aux Etats unis avec le Pr Goldberg à UCLA, Los
              Angeles et au Canda avec le Pr Rootman à Vancouver.
            </Trans>
          </p> */}
        </Col>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col style={{ paddingTop: '15px' }}>
          <p className="pAboutNumber" style={{}}>
            7000+
          </p>
          <p className="pAboutOperation">
            <Trans t={t}>Opérations réussies</Trans>
          </p>
        </Col>
        <Col style={{ backgroundColor: '#FAFAFA', paddingTop: '15px' }}>
          <p className="pAboutNumber">7000+</p>
          <p className="pAboutOperation">
            <Trans t={t}>Opérations réussies</Trans>
          </p>
        </Col>
        <Col style={{ paddingTop: '15px' }}>
          <p className="pAboutNumber">7000+</p>
          <p className="pAboutOperation">
            <Trans t={t}>Opérations réussies</Trans>
          </p>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col style={{ marginBottom: '2rem' }}>
          <h2 style={{ textAlign: 'center', fontFamily: 'Elsie' }}>
            <Trans t={t}>Our Awesome Stuff</Trans>
          </h2>
        </Col>
      </Row>

      <Row
        className="mt-3 mb-5"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Col
          className="paraAbout px-5 text-center text-md-left"
          style={
            i18n.language === 'ar'
              ? {
                  textAlign: 'right',
                  flexGrow: 0,
                }
              : {
                  textAlign: 'justify',
                  flexGrow: 0,
                }
          }
        >
          <img className="stafImg" src={aboutDR} alt="Dr.Ahmed Ben Said" />
        </Col>

        <Col
          style={{
            minWidth: '300px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <h4 className="mt-3 mt-sm-0 text-center text-md-left">
            Dr.Ahmed Ben Said
          </h4>
          <p style={{ color: '#47D1F3' }} className="text-center text-md-left">
            Surgeon Ophtalmo
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quo earum
            molestiae error, magnam quos nemo, deserunt ullam, dolorum pariatur
            ipsam consectetur sint dicta amet quia? Nesciunt eligendi ex nemo
            error?
          </p>
        </Col>
      </Row>
      <Row
        className="mt-3 mb-5"
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row-reverse',
        }}
      >
        <Col
          className="px-5 text-center text-md-left"
          style={
            i18n.language === 'ar'
              ? {
                  textAlign: 'right',
                  flexGrow: 0,
                }
              : {
                  textAlign: 'justify',
                  flexGrow: 0,
                }
          }
        >
          <img className="stafImg" src={aboutDR} alt="Dr.Ahmed Ben Said" />
        </Col>

        <Col
          style={{
            minWidth: '300px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingLeft: '3.125rem',
          }}
        >
          <h4 className="mt-3 mt-sm-0 text-center text-md-left">
            Dr.Ahmed Ben Said
          </h4>
          <p style={{ color: '#47D1F3' }} className="text-center text-md-left">
            Surgeon Ophtalmo
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quo earum
            molestiae error, magnam quos nemo, deserunt ullam, dolorum pariatur
            ipsam sint dicta amet quia? Nesciunt eligendi ex nemo error?
          </p>
        </Col>
      </Row>
      <Row
        className="mt-3 mb-5"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Col
          className="paraAbout px-5 text-center text-md-left"
          style={
            i18n.language === 'ar'
              ? {
                  textAlign: 'right',
                  flexGrow: 0,
                }
              : {
                  textAlign: 'justify',
                  flexGrow: 0,
                }
          }
        >
          <img className="stafImg" src={aboutDR} alt="Dr.Ahmed Ben Said" />
        </Col>

        <Col
          style={{
            minWidth: '300px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <h4 className="mt-3 mt-sm-0 text-center text-md-left">
            Dr.Ahmed Ben Said
          </h4>
          <p style={{ color: '#47D1F3' }} className="text-center text-md-left">
            Surgeon Ophtalmo
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quo earum
            molestiae error, magnam quos nemo, deserunt ullam, dolorum pariatur
            ipsam consectetur sint dicta amet? Nesciunt eligendi ex nemo error?
          </p>
        </Col>
      </Row>
      <Row
        className="mt-3 mb-5"
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row-reverse',
        }}
      >
        <Col
          className="px-5 text-center text-md-left"
          style={
            i18n.language === 'ar'
              ? {
                  textAlign: 'right',
                  flexGrow: 0,
                }
              : {
                  textAlign: 'justify',
                  flexGrow: 0,
                }
          }
        >
          <img className="stafImg" src={aboutDR} alt="Dr.Ahmed Ben Said" />
        </Col>

        <Col
          style={{
            minWidth: '300px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingLeft: '3.125rem',
          }}
        >
          <h4 className="mt-3 mt-sm-0 text-center text-md-left">
            Dr.Ahmed Ben Said
          </h4>
          <p style={{ color: '#47D1F3' }} className="text-center text-md-left">
            Surgeon Ophtalmo
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quo earum
            molestiae error, magnam quos nemo, deserunt ullam, pariatur ipsam
            consectetur sint dicta amet quia? Nesciunt eligendi ex nemo error?
          </p>
        </Col>
      </Row>
    </Container>
  )
}
