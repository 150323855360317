import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'react-bootstrap'

export default function ContactMobile() {
  return (
    <Col xs={12} className="barmobile">
      <Row style={{ justifyContent: 'space-around' }}>
        <Col style={{ flexGrow: 0 }}>
          <Row style={{ flexWrap: 'nowrap' }}>
            <Col style={{ flexGrow: 0, paddingRight: 'unset' }}>
              <FontAwesomeIcon
                style={{ fontSize: '20px', color: '#47D1F3' }}
                icon={faPhoneVolume}
              />
            </Col>
            <Col style={{ flexGrow: 0, paddingLeft: 'unset' }}>
              <b
                style={{
                  whiteSpace: 'nowrap',
                  fontSize: '13px',
                  paddingTop: '4px',
                }}
              >
                +216 12 123 123
              </b>
            </Col>
          </Row>
        </Col>
        <Col style={{ flexGrow: 0 }}>
          <Row style={{ flexWrap: 'nowrap' }}>
            <Col style={{ flexGrow: 0, paddingRight: 'unset' }}>
              <FontAwesomeIcon
                style={{
                  fontSize: '15px',
                  color: '#47D1F3',
                }}
                icon={faEnvelope}
              />
            </Col>
            <Col style={{ flexGrow: 0, paddingLeft: '2px' }}>
              <b
                style={{
                  whiteSpace: 'nowrap',
                  fontSize: '13px',
                  paddingTop: '4px',
                }}
              >
                contact@drbensaid.com
              </b>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  )
}
