import React from 'react'
import { Row, Col } from 'react-bootstrap'
import './FooterUp.css'
import { Trans, useTranslation } from 'react-i18next'
import insta from '../../assets/insta.png'
//import { ShowPhotos } from "../../api/instagram/showPhotos";
import I1 from '../../assets/I1.jpg'
import I3 from '../../assets/I3.jpg'
import I4 from '../../assets/I4.jpg'
import I5 from '../../assets/I5.jpg'
import I6 from '../../assets/I6.jpg'
import I7 from '../../assets/I7.jpg'
import I9 from '../../assets/I9.jpg'

export default function FooterInst() {
  const { t } = useTranslation()
  // const [posts, setPosts] = useState([]);
  // const [reload, setReload] = useState(true);
  // useEffect(() => {
  //   ShowPhotos().then((res: any) => {
  //     setPosts(res);
  //     setReload(false);
  //   });
  // }, []);

  return (
    <Row style={{ marginBottom: 'unset' }}>
      <Col
        xs="12"
        md={true}
        className="justify-content-center sm-justify-content-flex-start colFooter"
        style={{
          display: 'flex',
          backgroundColor: '#F8F8F8',
          height: '100px',
        }}
      >
        <img
          src={insta}
          alt="instagram"
          style={{ height: '32px', margin: '35px 2px 35px 15px' }}
        />
        <p
          style={{
            fontSize: '16px',
            fontFamily: 'Raleway',
            padding: '40px 5px 40px 5px',
            fontWeight: 600,
            whiteSpace: 'nowrap',
          }}
        >
          <Trans t={t}>Suivez-moi sur Instagram</Trans>
        </p>
      </Col>
      <Col className="imgFooter">
        {/* {reload ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "40rem",
            }}
          >
            <Spinner animation="border" variant="dark" />
          </div>
        ) : (
          posts.map((post: any, index: number) =>
            post.carousel_media_count > 1 ? (
              <Carousel indicators={false}>
                {post.carousel_media.map((media: any, index: number) => (
                  <Carousel.Item>
                    <img
                      src={media.image_versions2.candidates[1].url}
                      alt="instagram"
                      style={{ width: "6.25rem", height: "6.25rem" }}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : (
              <img
                src={post.image_versions2.candidates[1].url}
                alt="instagram"
                style={{ width: "6.25rem", height: "6.25rem" }}
              />
            )
          )
        )} */}
      </Col>
      <Col className="imgFooter">
        <img src={I1} alt="instagram" />
      </Col>
      <Col className="imgFooter">
        <img src={I3} alt="instagram1" />
      </Col>
      <Col className="imgFooter">
        <img src={I4} alt="instagram2" />
      </Col>
      <Col className="imgFooter">
        <img src={I5} alt="instagram3" />
      </Col>
      <Col className="imgFooter">
        <img src={I6} alt="instagram4" />
      </Col>
      <Col className="imgFooter">
        <img src={I7} alt="instagram5" />
      </Col>
      <Col className="imgFooter">
        <img src={I9} alt="instagram6" />
      </Col>
    </Row>
  )
}
