// import React, { useState, useEffect } from "react";
// import "./media.css";
// import { Card, Container, Row, Col, Spinner } from "react-bootstrap";
// import { Show } from "../../api/media";
// import ModelB from "./Model";

// function Media() {
//   const [title, setTitle] = useState("");
//   const [description, setDescription] = useState("");
//   const [img, setImg] = useState("");
//   const [show, setShow] = useState(false);
//   const [data, setData] = useState([]); //@ts-ignore
//   const [reload, setReload] = useState(true);

//   useEffect(() => {
//     Show().then((response) => {
//       setData(response);
//       setReload(false);
//     });
//   }, []);

//   const setModel = (e: any, index: any, img: any) => {
//     //@ts-ignore
//     setTitle(data[index].title); //@ts-ignore

//     setDescription(data[index].description);
//     setImg(img);
//     setShow(true);
//   };

//   const closeHandler = () => {
//     setShow(false);
//   };

//   return (
//     <Container>
//       {reload ? (
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             width: "100%",
//             height: "100vh",
//           }}
//         >
//           <Spinner animation="border" variant="dark" />
//         </div>
//       ) : (
//         <Row>
//           {data.map((item: any, index: number) => {
//             const img =
//               item.type === "image" ? (
//                 <Card.Img
//                   variant="top"
//                   src={item.media.url}
//                   style={{
//                     width: "100%",
//                     cursor: "pointer",
//                     maxHeight: "140px",
//                   }}
//                 />
//               ) : (
//                 <video
//                   controls
//                   style={{
//                     width: "100%",
//                     cursor: "pointer",
//                   }}
//                 >
//                   <source src={item.media.url} />
//                 </video>
//               );
//             return (
//               <Col
//                 xs={6}
//                 md={3}
//                 onClick={(e: any) => {
//                   setModel(e, index, img);
//                 }}
//               >
//                 <Card
//                   style={{
//                     width: "100%",
//                     marginRight: "12px",
//                     marginBottom: "10px",
//                     minHeight: "250px",
//                     marginTop: "30px",
//                   }}
//                 >
//                   {img}
//                   <Card.Body
//                     style={{
//                       textAlign: "center",
//                     }}
//                   >
//                     <Card.Title>
//                       <h6>{item.title}</h6>
//                     </Card.Title>
//                     <p>{item.description}</p>
//                   </Card.Body>
//                 </Card>
//               </Col>
//             );
//           })}
//         </Row>
//       )}
//       <ModelB
//         title={title}
//         description={description}
//         img={img}
//         show={show}
//         closeHandler={closeHandler}
//       />
//     </Container>
//   );
// }

// export { Media as default };
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SidebarPhotos from './SidebarPhotos'
import './media.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../i18n'

export default function Media() {
    const { t } = useTranslation()

    return (
        <Container fluid>
            <h1
                style={{
                    textAlign: 'center',
                    marginBottom: '40px',
                    fontFamily: 'Elsie',
                    fontSize: '50px',
                }}
                className="py-4"
            >
                <Trans t={t}>Galerie de photos</Trans>
            </h1>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <SidebarPhotos />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                ></Col>
            </Row>
        </Container>
    )
}
