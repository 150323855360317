import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function BlepharoplastieSuperieure() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }

    return (
        <Container fluid>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                ></Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h1 style={styleAr} className="py-4">
                        <Trans t={t}>blépharoplastie supérieure</Trans>
                    </h1>
                </Col>
            </Row>

            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le concept de blépharoplastie qui consiste à
                                    enlever la peau et la graisse en excès a
                                    changé ces dernières années. Aujourd’hui la
                                    blépharoplastie est définie comme un
                                    ensemble de techniques chirurgicales et non
                                    chirurgicales visant à améliorer l’apparence
                                    des paupières et du regard.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La paupière et la région péri palpébrale
                                    avoisinante comme le front, le sourcil, la
                                    patte d’oie subissent des changements tout
                                    au long de la vie. Ces changements
                                    comprennent la perte d'élasticité, l'excès
                                    de peau, l’apparition des poches graisseuses
                                    ou l’affaissement de la graisse ou sa
                                    disparition.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Tous ces changements peuvent donner au
                                    regard un aspect fatigué et vieilli.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Avant de poser un plan pour le traitement,
                                    deux à trois consultations s’imposent afin
                                    de comprendre les demandes du patient,
                                    d’analyser avec lui son visage, son regard
                                    en étudiant son vieillissement et tout
                                    changement par rapport à des anciennes
                                    photos.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Dans certains cas, on réalise une excision
                                    cutanée avec ou non de la graisse pour
                                    donner aux paupières et au regard un aspect
                                    plus jeune. Dans d’autres cas et devant un
                                    affaissement de la graisse on peut faire une
                                    injection de cellules adipocytes graisseuses
                                    (lipostructure) associée ou non à une
                                    excision de la peau. D’autres moyens
                                    médicaux comme la toxine botulinique ou
                                    autres sont utilisés, ainsi que d’autres
                                    gestes chirurgicaux comme la réparation d’un
                                    ptosis (la paupière qui tombe, voir chapitre
                                    ptosis), le repositionnement du sourcil
                                    (lifting du sourcil) et rarement une
                                    fixation de la glande lacrymale.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La chirurgie se fait dans la grande majorité
                                    des cas sous AL et en ambulatoire, et
                                    l’incision se fait au niveau du pli
                                    palpébrale et sera invisible. Le patient
                                    quitte la clinique deux à trois heures après
                                    l’intervention et sera contrôlé par son
                                    chirurgien au premier jour et au septième
                                    jour.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Dr Ben said est spécialiste des paupières.
                                    Il prend en charge également les patients
                                    qui ont été opérés ailleurs avec un mauvais
                                    résultat esthétique ou fonctionnel (comme la
                                    rétraction de la paupière inférieure ou
                                    l’œil rond), les patients avec une mauvaise
                                    cicatrice ou un ectropion (le bord libre de
                                    la paupière est retourné en dehors) et les
                                    patients qui présentent
                                </Trans>{' '}
                                <Trans t={t}>
                                    une lagophtalmie ou mauvaise fermeture des
                                    yeux.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
