import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import ModelAdd from "./ModelAdd";
import ModelUpdate from "./ModelUpdate";
import { history } from "../history";

import { Show, Remove } from "../../api/media";

export default function Media() {
  //test auth
  if (!sessionStorage.getItem("admin-token")) history.push("/admin");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [id, setId] = useState("");
  const [showAjout, setShowAjout] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [data, setData] = useState([]);

  //@ts-ignore
  useEffect(() => {
    Show().then((response) => setData(response));
  }, []);

  const deleteMedia = async (index: number) => {
    //@ts-ignore
    await Remove(data[index]._id);
    const media = await Show();
    setData(media);
  };

  const updateInfo = (index: any, img: any) => {
    //@ts-ignore
    setId(data[index]._id);
    //@ts-ignore
    setTitle(data[index].title);
    //@ts-ignore
    setDescription(data[index].description);
  };

  const closeHandler = () => {
    setShowAjout(false);
    setShowUpdate(false);
  };

  return (
    <Container>
      <Row
        className="pt-5"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Col style={{ flexGrow: 0 }}>
          <Button
            variant="secondary"
            onClick={() => {
              sessionStorage.clear();
              history.push("/");
            }}
          >
            Signout
          </Button>
        </Col>
      </Row>
      <h1>My photos/videos</h1>
      <Row>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="secondary"
            style={{
              backgroundColor: "#4FEA9E",
              border: "none",
              marginBottom: "15px",
            }}
            onClick={() => {
              setShowAjout(true);
            }}
          >
            Add media +
          </Button>
        </Col>
      </Row>
      <Row>
        {data.map((item: any, index: number) => {
          const img =
            item.type === "image" ? (
              <Card.Img
                variant="top"
                src={item.media.url}
                style={{ width: "100%", maxHeight: "140px" }}
              />
            ) : (
              <video controls style={{ width: "100%" }}>
                <source src={item.media.url} />
              </video>
            );
          return (
            <Col xs={6} md={3} key={index}>
              <Card
                style={{
                  width: "100%",
                  marginRight: "12px",
                  marginBottom: "10px",
                  minHeight: "320px",
                }}
              >
                <Card.Body style={{ textAlign: "center" }}>
                  {img}
                  <Card.Title>
                    <h6>{item.title}</h6>
                  </Card.Title>
                  <p>{item.description}</p>
                </Card.Body>
                <Card.Footer
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Button
                    variant="secondary"
                    onClick={() => {
                      deleteMedia(index);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="secondary"
                    style={{
                      backgroundColor: "#4FEA9E",
                      marginLeft: "20px",
                    }}
                    onClick={() => {
                      updateInfo(index, img);
                      setShowUpdate(true);
                    }}
                  >
                    Modify
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          );
        })}
      </Row>

      {/*Add Media*/}
      <ModelAdd
        showAjout={showAjout}
        closeHandler={closeHandler}
        setData={setData}
      />

      {/*Update Media */}
      <ModelUpdate
        showUpdate={showUpdate}
        closeHandler={closeHandler}
        title={title}
        description={description}
        setData={setData}
        id={id}
      />
    </Container>
  );
}
