import React, { useState, useEffect } from 'react'

interface Props {
  img: any
}

export default function ReadFile(props: Props) {
  let [img, setImg] = useState('')
  let [type, setType] = useState('image')

  useEffect(() => {
    if (props.img && props.img.name) {
      let file = props.img
      setType(file.type.slice(0, 5))
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = (e: any) => {
        if (reader.result) setImg(reader.result.toString())
      }
    } else {
      setImg('')
    }
  }, [props.img])

  return (
    <div>
      {type === 'image' ? (
        <img
          alt="img"
          src={img}
          style={
            img.length !== 0
              ? {
                  display: 'block',
                  width: '100px',
                  height: '100px',
                }
              : { display: 'none' }
          }
        />
      ) : (
        <video
          controls
          style={
            img.length !== 0
              ? {
                  display: 'block',
                  width: '100px',
                  height: '100px',
                }
              : { display: 'none' }
          }
        >
          <source src={img} />
        </video>
      )}
    </div>
  )
}
