import axios from "axios";
const Remove = async (id: any) => {
  const key = sessionStorage.getItem("admin-token");
  const options = {
    headers: {
      "auth-token": key,
    },
  };
  const response = await axios.delete(
    "https://dr-ahmed-ben-said.herokuapp.com/media/" + id,
    options
  );
  return response.data;
};

export default Remove;
