import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation, Trans } from 'react-i18next'
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'

export default function Testimonials() {
  const { t } = useTranslation()
  return (
    <div className="mt-5 mb-5">
      <Row>
        <Col className="title" xs="12">
          <h2 style={{ fontFamily: 'Elsie', fontSize: '34px' }}>
            <Trans t={t}>Testimonials</Trans>
          </h2>
        </Col>
        <Col xs="12">
          <section className="text-center mt-5">
            <MDBCarousel
              activeItem={1}
              length={3}
              testimonial
              interval={7000}
              showIndicators={false}
              className="no-flex"
            >
              <MDBCarouselInner>
                <MDBCarouselItem itemId="1" className="carouselTestimonia">
                  <img
                    src="https://mdbootstrap.com/img/Photos/Avatars/img%20(30).jpg"
                    className="rounded-circle img-fluid mb-4"
                    alt="avatar1"
                  />
                  <p>
                    <FontAwesomeIcon
                      icon={faQuoteLeft}
                      style={{ color: '#2196f3' }}
                    />
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Quod eos id officiis hic tenetur quae quaerat ad velit ab.
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Dolore cum accusamus eveniet molestias voluptatum inventore
                    laboriosam labore sit, aspernatur praesentium iste impedit
                    quidem dolor veniam.
                  </p>
                  <b style={{ fontSize: '18px' }}>Anna Deynah</b>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="2" className="carouselTestimonia">
                  <img
                    src="https://mdbootstrap.com/img/Photos/Avatars/img%20(31).jpg"
                    className="rounded-circle img-fluid mb-4"
                    alt="avatar2"
                  />
                  <p>
                    <FontAwesomeIcon
                      icon={faQuoteLeft}
                      style={{ color: '#2196f3' }}
                    />
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                    odit aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                    qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                    velit, sed quia non numquam eius modi tempora incidunt ut
                    labore.
                  </p>
                  <b style={{ fontSize: '18px' }}>Maria Kate</b>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="3" className="carouselTestimonia">
                  <img
                    src="https://mdbootstrap.com/img/Photos/Avatars/img%20(3).jpg"
                    className="rounded-circle img-fluid mb-4"
                    alt="avatar3"
                  />

                  <p>
                    <FontAwesomeIcon
                      icon={faQuoteLeft}
                      style={{ color: '#2196f3' }}
                    />
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum. Sed ut perspiciatis
                    unde omnis iste natus error sit voluptatem accusantium
                    doloremque laudantium.
                  </p>
                  <b style={{ fontSize: '18px' }}>John Doe</b>
                </MDBCarouselItem>
              </MDBCarouselInner>
            </MDBCarousel>
          </section>
        </Col>
      </Row>
    </div>
  )
}
