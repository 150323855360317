import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function SyndromeSec() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }

    return (
        <Container fluid>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                ></Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h1 style={styleAr} className="py-4">
                        <Trans t={t}>Le syndrome sec</Trans>
                    </h1>
                </Col>
            </Row>

            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h4 style={styleAr}>
                        <Trans t={t}>Definition</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La sécheresses lacrymale est fréquente,
                                    encore faut-il la rechercher. Les plaintes
                                    exprimées par les patients sont bien peu
                                    typiques sensation de sable, gêne importante
                                    à la lumière, brûlures, gêne à l'ouverture
                                    des yeux le matin, démangeaisons, baisse de
                                    l'acuité visuelle.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les paupières balayent en permanence, par
                                    leur clignement, la surface oculaire en
                                    permettant aux larmes de s'étaler à la
                                    surface de la cornée.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Ces larmes, sécrétées en permanence par les
                                    glandes lacrymales et d’autres glandes
                                    palpébrales sont faites en grande majorité
                                    d'eau (glandes lacrymales) mais pas
                                    uniquement les lipides et le mucus (glandes
                                    palpébrales) sont indispensables à la bonne
                                    stabilité du film lacrymal qui s'étend donc
                                    comme une fine barrière protectrice devant
                                    la cornée, lui évitant dessèchement,
                                    infection, opacification.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le syndrome sec correspond à une des trois
                                    propositions suivantes
                                </Trans>
                                <ul style={{ marginTop: '8px' }}>
                                    <li>
                                        <Trans t={t}>
                                            diminution de la qualité des larmes
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            diminution de la quantité des larmes
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t}>
                                            diminution de la stabilité du film
                                            lacrymal
                                        </Trans>
                                    </li>
                                </ul>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Comment faire le diagnostic ?</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le premier problème posé par le patient
                                    atteint de syndrome sec est celui de son
                                    diagnostic
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les questions appréciant la capacité de
                                    pleurer des patients restent déterminantes
                                    rares sont les patients atteints de syndrome
                                    sec qui conservent encore la possibilité de
                                    pleurer en épluchant les oignons ou lors
                                    d'émotions fortes. Le classique test de
                                    Schirmer est certainement utile mais ne sera
                                    positif que dans les formes évoluées. On
                                    peut même voir un larmoiement réactionnel
                                    dans les formes débutantes.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Plus déterminants sont l'examen du Break-up
                                    time et la prise de fluorescéine qui
                                    permettront de faire le tri entre des
                                    symptomatologies où la part subjective reste
                                    souvent majeure. L'examen par le vert de
                                    lissamine ou le rose bengale reste
                                    certainement l'un des examens clés de
                                    l'analyse de la surface oculaire.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Longtemps considéré comme une analyse du
                                    mucus et un marqueur de la vitalité
                                    cellulaire, le test au rose bengale serait
                                    en fait plutôt un marqueur de la souffrance
                                    cellulaire.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>Le score de</Trans>{' '}
                                <Trans t={t}>
                                    Van Bijsterveld apprécie l'intensité du
                                    marquage il considère chaque secteur (nasal,
                                    temporal et cornéen pour lui attribuer un
                                    score de 0 a 3) et donne un score sur 9.
                                    Toutefois, même avec ces différents tests,
                                    le diagnostic de syndrome sec repose souvent
                                    sur une présomption, somme de plusieurs
                                    éléments.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    En effet, il n'existe pas réellement de test
                                    pathognomonique du syndrome sec. Dans
                                    certains cas, l'empreinte conjonctivale peut
                                    avoir un certain intérêt. Cette analyse des
                                    couches superficielles conjonctivales permet
                                    d'objectiver l'intensité de la souffrance
                                    cellulaire épithéliale et la diminution du
                                    nombre de cellules à mucus.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Jusqu'où faut-il aller ?</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L'ophtalmologiste se trouve alors confronté
                                    à un patient ayant une sécrétion lacrymale
                                    défaillante et des signes oculaires de
                                    souffrance cellulaire. Les formes cliniques
                                    de syndromes secs sont extrêmement variées
                                    il est certain qu'entre une discrète
                                    sensation subjective de sécheresse et les
                                    signes objectifs de souffrance cellulaire,
                                    il y a une grande variété où l'acharnement
                                    pour trouver une cause systémique n'a pas
                                    toujours sa place.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Là encore, l'interrogatoire devra primer
                                    l'absence de prise de sédatifs au long cours
                                    ou d'anxiolytiques, la notion de bouche
                                    sèche associée ou des douleurs articulaires
                                    ou tout autre point d'appel systémique fera
                                    rechercher une maladie de système.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Nombreuses sont les pathologies, en dehors
                                    de la polyarthrite rhumatoide, qui associent
                                    un syndrome sec à une maladie générale
                                    (hépatite c, cirrhose biliaire primitive,
                                    sarcoïdose...)
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Les causes du syndrome sec</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Si la grande majorité des syndromes secs est
                                    liée à une involution sénile de la glande
                                    lacrymale, d'autres facteurs surajoutés sont
                                    susceptibles d'aggraver la situation
                                    autrefois bien équilibrée pour la faire
                                    basculer vers une atteinte mal ressentie par
                                    le patient.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    En effet, la diminution de la fonction
                                    lacrymale avec l'âge est un fait bien connu
                                    mais elle est souvent aggravée par la prise
                                    prolongée de médicaments entraînant une
                                    diminution de la sécrétion lacrymale comme
                                    les antidépresseurs, les neuroleptiques, les
                                    bétabloquants.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les collyres après une chirurgie oculaire ou
                                    suite à un traitement d’une pathologie
                                    oculaire peuvent bouleverser l’équilibre
                                    lacrymal et accélérer ou provoquer
                                    l’apparition d’un syndrome sec.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le syndrome sec est actuellement plus
                                    fréquent en raison des conditions
                                    extérieures qui favorisent le dessèchement
                                    climatisation, atmosphère surchauffée mais
                                    non humidifiée des habitations, pollution et
                                    surtout abus de traitement diminuant la
                                    sécrétion de larmes.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Toutefois, certaines maladies générales
                                    s'accompagnent d'un syndrome sec invalidant.
                                    Elles donnent des formes de syndrome sec
                                    sévères et nécessitent des traitements
                                    adaptés.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>
                            Syndromes secs comment ne pas s'y noyer ?
                        </Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Il s'agit d'une affection dont la fréquence
                                    semble avoir nettement augmenté depuis ces
                                    dernières années.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Toutefois, plus qu'une altération réelle du
                                    film lacrymal liée à une hypothétique cause
                                    systémique, il faut certainement plutôt y
                                    voir la responsabilité de la détérioration
                                    des conditions de vie augmentation de la
                                    pollution, air conditionné, assèchement
                                    global de l'atmosphère par des isolations de
                                    plus en plus parfaites...
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Face à cette demande accrue de traitement
                                    suppléant une sécrétion lacrymale
                                    défaillante, le praticien se trouve souvent
                                    confronté à une orientation diagnostique
                                    mais également à un problème de choix
                                    thérapeutique.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Comment traiter un syndrome sec ?</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Il n'y a pas de traitement idéal et les
                                    praticiens devront avant tout respecter
                                    l'adage. En effet, l'instillation chronique
                                    de collyres peut être à l'origine d'une
                                    inflammation conjonctivale source de
                                    troubles mal ressentis par le patient.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    On se tournera donc avant tout vers des
                                    collyres en unidoses qui ont l'avantage de
                                    dispenser le traitement avec le facteur
                                    actif mais sans conservateur.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Bien entendu, l'escalade thérapeutique
                                    dépend de la sévérité des troubles de
                                    l'instillation de sérum physiologique à
                                    l'administration des gels, il y a tout un
                                    panel à la disposition du praticien à
                                    adapter selon les signes cliniques.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les autres petits moyens qui consistent à
                                    améliorer l'humidification des pièces, à
                                    supprimer les produits diminuant la
                                    sécrétion de larmes sont des éléments non
                                    négligeables. Bien entendu, le traitement
                                    d'un syndrome sec évoluant dans le cadre
                                    d'une maladie générale passera avant tout
                                    par le traitement de la maladie générale.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <strong>
                                    <Trans t={t}>Pour conclure...</Trans>
                                </strong>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L'augmentation du nombre de syndromes secs
                                    tient moins à leur meilleur dépistage qu'à
                                    une détérioration des conditions de vie
                                    (pollution, assèchement de l'atmosphère
                                    ...).
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <strong>
                                    <Trans t={t}>Les formes légères</Trans>
                                </strong>{' '}
                                <Trans t={t}>
                                    sont facilement accessibles à des
                                    traitements peu lourds.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <strong>
                                    <Trans t={t}>Les formes graves</Trans>
                                </strong>{' '}
                                <Trans t={t}>
                                    restent toutefois peu sensibles aux
                                    traitements locaux, les substituts de larmes
                                    n'étant actuellement que des pis aller
                                    destinés à améliorer la symptomatologie mais
                                    absolument pas à traiter l'affection
                                    initiale.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
