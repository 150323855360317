import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function Ptosis() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }

    return (
        <Container fluid>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                ></Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h1 style={styleAr} className="py-4">
                        <Trans t={t}>Ptosis</Trans>
                    </h1>
                </Col>
            </Row>

            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h4 style={styleAr}>
                        <Trans t={t}>Definition</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le ptosis, ou ptôse palpébrale ou encore
                                    blépharoptôse, est la chute de la paupière
                                    supérieure, c'est à dire le bord de la
                                    paupière supérieure où se trouve les cils
                                    est plus bas que la normale. Le ptosis peut
                                    être symétrique ou asymétrique. Il peut être
                                    minime, modérée ou sévère, cachant la
                                    pupille gênant ainsi sa vison. Le ptosis
                                    minime n’est pas toujours connu par le
                                    patient et par son entourage.
                                </Trans>
                            </p>

                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Esthétiquement le ptosis peut provoquer chez
                                    le patient un aspect fatigué, triste,
                                    somnolent. Le sourcil, et les plis
                                    palpébraux sont souvent surélevés pour
                                    compenser la chute de la paupière.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Au niveau du front nous pouvons voir des
                                    rides très prononcées témoignant d’une
                                    activité du muscle frontal plus importante
                                    du coté du ptosis pour soulevé les paupières
                                    tombantes et compenser sa chute. Ceci est
                                    plus remarquable quand le ptosis est
                                    unilatéral avec des rides plus marquées.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les enfants en présence d’un ptosis
                                    asymétrique sévère peuvent développer une
                                    amblyope (œil paresseux) avec une baisse
                                    permanente de la vision. D’où l’intérêt de
                                    traiter ces patients dans les plus brefs
                                    délais.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Les causes du Ptosis</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le ptosis peut être congénital (présent dès
                                    la naissance) ou acquis.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le ptosis congénital peut être unilatéral ou
                                    bilatérale, son traitement dépend de sa
                                    sévérité et de l’âge de l’enfant
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La principale cause du ptosis acquis est
                                    <strong>
                                        <Trans t={t}>
                                            {' '}
                                            le ptosis involutionel sénile
                                        </Trans>
                                    </strong>{' '}
                                    du au vieillissement du tendon du muscle
                                    releveur de la paupière supérieure qui
                                    permet de soulever la paupière (ce muscle
                                    soulève la paupière des milliers de fois par
                                    jour pendant toute la vie, son tendon peut
                                    se détacher avec le temps).
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>D’autre causes comme</Trans>
                                <strong>
                                    <Trans t={t}> le ptosis neurogène</Trans>
                                </strong>{' '}
                                <Trans t={t}>
                                    (faiblesse du releveur de la paupière
                                    résultant d'une interruption de la commande
                                    nerveuse) est du à une atteinte du nerf
                                    oculomoteur ou à un syndrome de Claude
                                    Bernard-Horner. Aussi
                                </Trans>{' '}
                                <strong>
                                    <Trans t={t}>le ptosis myogène</Trans>
                                </strong>{' '}
                                <Trans t={t}>
                                    résulte d'une anomalie de la transmission
                                    neuromusculaire comme dans la myasthénie,
                                    dans l’atteinte myopathique, la dystrophie
                                    myotonique et les myopathies oculaires.
                                    Enfin, il y a le ptosis acquis d’
                                </Trans>
                                <strong>
                                    <Trans t={t}>origine mécanique</Trans>
                                </strong>
                                <Trans t={t}>
                                    , observé en cas de tumeur, ou de
                                    traumatisme.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les faux ptosis ou pseudo ptosis est très
                                    important à rechercher et à connaitre.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Un dermatochalasis (excès important de la
                                    peau) peut être confondu avec un ptosis, ce
                                    qui peut induire le patient en erreur. D’où
                                    l’intérêt d’un examen minutieux pour évaluer
                                    le muscle releveur de la paupière supérieure
                                    et la position du bord libre palpébrale. Il
                                    suffit d’opérer le dermatochlasis par une
                                    blépharoplastie supérieure pour avoir un bon
                                    résultat et ne plus avoir l’effet d’une
                                    paupière tombante ou de pseudo ptosis.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Comment corriger un ptosis ?</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le traitement du ptosis est principalement
                                    chirurgical.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les techniques sont variables et ceci dépend
                                    de la cause du ptosis, sa sévérité, la
                                    fonction du muscle releveur de la paupière
                                    supérieure et de la l’âge du patient.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La voie d’abord chirurgicale peut être
                                    antérieure à travers la peau au niveau du
                                    pli palpébrale qui va cacher la cicatrice.
                                    Elle consiste à resserrer et refixer le
                                    muscle releveur de la paupière supérieure,
                                    parfois on pratique une résection et une
                                    réinsertion du muscle en fonction du type,
                                    de la gravité et de la force du muscle
                                    releveur.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Dans les cas ou le ptosis est minime une
                                    approche postérieure avec une incision de la
                                    paupière dans sa partie en contact avec
                                    l’œil (conjonctive). C’est une résection de
                                    la conjonctive et du muscle de Müller. Cette
                                    technique sans cicatrice cutanée apparente
                                    permet de traiter les ptosis allant de à 3
                                    mm avec un résultat esthétique excellent
                                    respectant le contour palpébral.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Dans les cas où le ptosis est sévère (dit
                                    majeure ou totale) qui sont congénitaux dans
                                    la grande majorité des cas. Nous réalisons
                                    ce qu’on appelle une suspension frontale. On
                                    utilise comme une ceinture pour soulever la
                                    paupière et la fixer sur le muscle frontal
                                    qui continue à se contracter et qui fait
                                    bouger la paupière supérieure. Nous
                                    utilisons le fascia Lata (c’est le tissu qui
                                    couvre les muscle strié) prélevé au niveau
                                    de la cuisse, ou des biomatériaux comme le
                                    silicone, le nylon ou autres.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Pour les adultes et dans la grande majorité
                                    des cas la chirurgie se réalise sous
                                    anesthésie locale et en ambulatoire. Pour
                                    les enfants c’est toujours l’anesthésie
                                    générale.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
