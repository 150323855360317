import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import '../../Procedures/Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function Orbital() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : {}

    return (
        <Container fluid style={{ padding: '0 5%', marginBottom: '20px' }}>
            <h1
                style={{ textAlign: 'center', fontSize: '50px' }}
                className="py-4"
            >
                <Trans t={t}>Chirurgie orbitaire et cavité</Trans>
            </h1>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col md={{ span: 3, order: 1 }} xs={{ span: 12, order: 2 }}>
                    <p className="paraProcedure" style={styleAr}>
                        <a
                            href="/procedures/orbital-and-cavity-surgery/enucléation-evisceration-prothese"
                            className="aProcedure"
                        >
                            <Trans t={t}>
                                Enucléation éviscération prothèse
                            </Trans>
                        </a>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <a
                            href="/procedures/orbital-and-cavity-surgery/inflammation-et-infection-orbitaire"
                            className="aProcedure"
                        >
                            <Trans t={t}>
                                Inflammation et infection orbitaire
                            </Trans>
                        </a>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <a
                            href="/procedures/orbital-and-cavity-surgery/malformation-vasculaire-orbitaire"
                            className="aProcedure"
                        >
                            <Trans t={t}>
                                Malformation vasculaire orbitaire
                            </Trans>
                        </a>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <a
                            href="/procedures/orbital-and-cavity-surgery/oeil-thyroide-et-traitement"
                            className="aProcedure"
                        >
                            <Trans t={t}>Oeil thyroide et traitement</Trans>
                        </a>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <a
                            href="/procedures/orbital-and-cavity-surgery/tumeur-orbitaire"
                            className="aProcedure"
                        >
                            <Trans t={t}>Tumeur orbitaire</Trans>
                        </a>
                    </p>
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            L’orbite représente l’ensemble des éléments
                            anatomiques autour de l’œil délimitée par un cadre
                            osseux composé de plusieurs os On trouve la glande
                            lacrymale, les muscles oculomoteurs, le nerf
                            optique, les vaisseaux sanguins et lymphatiques, les
                            nerfs et la graisse orbitaire qui englobe le tout.
                        </Trans>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            Par la présence riche de toutes ses structures
                            tissulaire, cette région est le siège de pathologies
                            très variées
                        </Trans>
                    </p>
                    <h4 style={styleAr}>
                        <Trans t={t}>Inflammatoire</Trans>
                    </h4>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            L’inflammation se développe dans ces tissus créant
                            une masse de taille, de siège et de structure
                            particulière et différente entraînant souvent des
                            signes ophtalmologiques. Le diagnostic est facilité
                            par la biologie ainsi que la radiologie et
                            généralement confirmé par une biopsie. Le traitement
                            est souvent médical et varie selon les pathologies
                            retrouvées.
                        </Trans>
                    </p>
                    <h4 style={styleAr}>
                        <Trans t={t}>Tumorale</Trans>
                    </h4>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            Le diagnostic est posé dans la majorité des cas par
                            les examens cliniques et radiologiques et rarement
                            biologique. La biopsie est posée souvent pour
                            confirmer le diagnostic. Le traitement dépendra de
                            la nature exacte de la tumeur maligne, bénigne,
                            vasculaire, tissulaire ou autre …. Il sera appliqué
                            après une analyse minutieuse du dossier. Il peut
                            être chirurgical, médical (chimiothérapie ou
                            radiothérapie), ou même l’association de plusieurs
                            procédures dans le cadre de protocoles bien établis.
                        </Trans>
                    </p>
                    <h4 style={styleAr}>
                        <Trans t={t}>Malformations vasculaires</Trans>
                    </h4>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            Les malformations vasculaires proprement dites sont
                            divisées selon leur flux. Celles à bas débit sont
                            veineuses (comme les varices orbitaires),
                            capillaro-veineuses ou lymphatiques (lymphangiomes).
                            Les malformations à haut débit, plus rares, sont
                            artério-veineuses ou artérielles (anévrisme).
                        </Trans>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            La prise en charge dépend du type de la lésion, de
                            sa nature et de son siège. Le traitement peut être
                            médical, chirurgical et parfois d’autres techniques
                            interventionnelles comme l’embolisation (obstruer un
                            vaisseau en injectant un embole synthétique) ou la
                            sclérothérpie (un médicament est injecté dans la
                            lésion pour la faire rétrécir).
                        </Trans>
                    </p>

                    <h4 style={styleAr}>
                        <Trans t={t}>Orbitopathie dysthyroïdienne</Trans>
                    </h4>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            L’orbitopthie dysthyroidienne (OD) ou Grave’s
                            disease ou thyroid eye disease (TED’s) chez les
                            anglo-saxon est une maladie inflammatoire
                            auto-immune liée à la glande thyroïde. Elle affecte
                            les structures orbitaires périoculaires, les
                            muscles, la graisse et les paupières.
                        </Trans>
                    </p>
                    <h4 style={styleAr}>
                        <Trans t={t}>Abcès de l’orbite</Trans>
                    </h4>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            Il s’agit d’une infection qu’il faut drainer
                            chirurgicalement et traiter par antibiothérapie.
                        </Trans>
                    </p>
                    <h4 style={styleAr}>
                        <Trans t={t}>Les cavités orbitaires</Trans>
                    </h4>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            La chirurgie des cavités orbitaires est l'ensemble
                            des interventions portant sur la région
                            orbito-palpébro-conjonctivale ayant pour objectif la
                            réfection du contenu intra-orbitaire permettant le
                            port d'une prothèse oculaire.
                        </Trans>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            Cette chirurgie est réalisée, soit parce qu'il
                            existe une mauvaise contention, soit parce que le
                            résultat esthétique est insuffisant. Il s'agit la
                            plupart du temps de patients anophtalmes (absence de
                            globe oculaire), l'œil ayant été perdu et/ou enlevé
                            à la suite d'un traumatisme, d'une brûlure, d'une
                            tumeur ou d'une malformation.
                        </Trans>
                    </p>
                </Col>
            </Row>
        </Container>
    )
}
