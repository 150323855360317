import axios from "axios";

const Add = async (title: string, description: string, img: string) => {
  const key = sessionStorage.getItem("admin-token");
  const media = new FormData();
  media.append("title", title);
  media.append("description", description);
  media.append("img", img);
  const options = {
    headers: {
      "auth-token": key,
    },
  };
  const response = await axios.post(
    "https://dr-ahmed-ben-said.herokuapp.com/media",
    media,
    options
  );
  return response.data;
};

export default Add;
