import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Button, Container, Modal } from 'react-bootstrap'

import { Update, Show } from '../../api/media'
import ShowImg from './ShowImg'

interface Props {
  id: string
  title: string
  description: string
  showUpdate: boolean
  setData(data: any): any
  closeHandler(): void
}

export default function ModelA(props: Props) {
  const [title, setTitle] = useState(props.title)
  const [description, setDescription] = useState(props.description)
  const [img, setImg] = useState('')

  useEffect(() => {
    setTitle(props.title)
    setDescription(props.description)
  }, [props.title, props.description])

  const submitForm = async () => {
    await Update(title, description, img, props.id)
    const data = await Show()
    props.setData(data)
    props.closeHandler()
  }

  return (
    <Modal show={props.showUpdate} onHide={props.closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 style={{ marginLeft: '100px' }}>Update</h2>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container
          fluid
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Col xs={12} md={12} style={{ margin: '20px' }}>
            <Form>
              <Form.Group as={Row} controlId="exampleForm.ControlInput1">
                <Form.Label
                  column
                  sm="2"
                  className="label pt-4 pt-sm-1 ml-2 ml-sm-0"
                >
                  Title
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="title"
                    value={title}
                    onChange={(e: any) => {
                      setTitle(e.target.value)
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label column sm="2" className="label ml-2 ml-sm-0">
                  Message
                </Form.Label>
                <Col sm="10" style={{ margin: 'auto' }}>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    name="description"
                    value={description}
                    onChange={(e: any) => {
                      setDescription(e.target.value)
                    }}
                  />
                </Col>
              </Form.Group>
              <Col>
                <ShowImg img={img} />
                <Form.Group>
                  <Form.File
                    id="exampleFormControlFile1"
                    label="Upload your media"
                    onChange={(e: any) => {
                      setImg(e.target.files[0])
                    }}
                  />
                </Form.Group>
              </Col>
            </Form>
          </Col>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.closeHandler}>
          Close
        </Button>
        <Button
          variant="primary"
          style={{
            backgroundColor: '#4FEA9E',
            border: 'none',
            padding: '7px 20px',
          }}
          onClick={submitForm}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
