import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Header from '../../Header'
import Home from '../../Home'
import Media from '../../Media'
import Contact from '../../Contact'
import Footer from '../../Footer'
import Procedures from '../../Procedures'
import Eyelid from '../../Procedures/Eyelid/Eyelid'
import EyelidM from '../../Media/Eyelid/EyelidM'
import Ectropion from '../../Procedures/Eyelid/Ectropion'
import EctropionM from '../../Media/Eyelid/EctropionM'
import Ptosis from '../../Procedures/Eyelid/Ptosis'
import PtosisM from '../../Media/Eyelid/PtosisM'
import TumeurPalpebrales from '../../Procedures/Eyelid/TumeurPalpebrales'
import TumeurPalpebralesM from '../../Media/Eyelid/TumeurPalpebralesM'
import Intubation from '../../Procedures/Eyelid/Intubation'
import IntubationM from '../../Media/Eyelid/IntubationM'
import Dacryocysthorhinostomie from '../../Procedures/Eyelid/Dacryocysthorhinostomie'
import DacryocysthorhinostomieM from '../../Media/Eyelid/DacryocysthorhinostomieM'
import Larmoiement from '../../Procedures/Eyelid/Larmoiement'
import LarmoiementM from '../../Media/Eyelid/LarmoiementM'
import SyndromeSec from '../../Procedures/Eyelid/SyndromeSec'
import SyndromeSecM from '../../Media/Eyelid/SyndromeSecM'
import MalpositionPalpébraleEntropion from '../../Procedures/Eyelid/MalpositionPalpébraleEntropion'
import MalpositionPalpébraleEntropionM from '../../Media/Eyelid/MalpositionPalpébraleEntropionM'
import PaupièresCanalicules from '../../Procedures/Eyelid/PaupièresCanalicules'
import PaupièresCanaliculesM from '../../Media/Eyelid/PaupièresCanaliculesM'
import Orbital from '../../Procedures/Orbital/Orbital'
import OrbitalM from '../../Media/Orbital/OrbitalM'
import EnucléationEviscerationProthese from '../../Procedures/Orbital/EnucléationEviscerationProthese'
import EnucléationEviscerationProtheseM from '../../Media/Orbital/EnucléationEviscerationProtheseM'
import InflammationEtInfectionOrbitaire from '../../Procedures/Orbital/InflammationEtInfectionOrbitaire'
import InflammationEtInfectionOrbitaireM from '../../Media/Orbital/InflammationEtInfectionOrbitaireM'
import MalformationVasculaireOrbitaire from '../../Procedures/Orbital/MalformationVasculaireOrbitaire'
import MalformationVasculaireOrbitaireM from '../../Media/Orbital/MalformationVasculaireOrbitaireM'
import OeilThyroideEtTraitement from '../../Procedures/Orbital/OeilThyroideEtTraitement'
import OeilThyroideEtTraitementM from '../../Media/Orbital/OeilThyroideEtTraitementM'
import TumeurOrbitaire from '../../Procedures/Orbital/TumeurOrbitaire'
import TumeurOrbitaireM from '../../Media/Orbital/TumeurOrbitaireM'
import Cosmetic from '../../Procedures/Cosmetic/Cosmetic'
import CosmeticM from '../../Media/Cosmetic/CosmeticM'
import BlepharoplastieInferieure from '../../Procedures/Cosmetic/BlepharoplastieInferieure'
import BlepharoplastieInferieureM from '../../Media/Cosmetic/BlepharoplastieInferieureM'
import BlepharoplastieSuperieure from '../../Procedures/Cosmetic/BlepharoplastieSuperieure'
import BlepharoplastieSuperieureM from '../../Media/Cosmetic/BlepharoplastieSuperieureM'
import Botox from '../../Procedures/Cosmetic/Botox'
import BotoxM from '../../Media/Cosmetic/BotoxM'
import Cernes from '../../Procedures/Cosmetic/Cernes'
import CernesM from '../../Media/Cosmetic/CernesM'
import PtosisEtBleph from '../../Procedures/Cosmetic/PtosisEtBleph'
import PtosisEtBlephM from '../../Media/Cosmetic/PtosisEtBlephM'
import About from '../../About'

export default function LayoutPublic() {
  return (
    <div>
      <Header />
      <Switch>
        <Route path="/" exact>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Doctor Ben Said Ahmed</title>
            <meta
              name="description"
              content="Dr ben said ahmed ophtalmologiste, chirurgie de la cataracte, du glaucome, esthétique des yeux, blépharoplastie, ptose et tumeurs des paupières, strabisme."
            />
            <link rel="canonical" href="https://drbensaid.com/" />
          </Helmet>
          <Home />
        </Route>
        <Route path="/procedures" exact>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link rel="canonical" href="https://drbensaid.com/procedures" />
          </Helmet>
          <Procedures />
        </Route>
        <Route path="/procedures/eyelid-and-lacrimal-surgery" exact>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/eyelid-and-lacrimal-surgery"
            />
          </Helmet>
          <Eyelid />
        </Route>
        <Route path="/procedures/eyelid-and-lacrimal-surgery/ectropion">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/eyelid-and-lacrimal-surgery/ectropion"
            />
          </Helmet>
          <Ectropion />
        </Route>
        <Route path="/procedures/eyelid-and-lacrimal-surgery/ptosis">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/eyelid-and-lacrimal-surgery/ptosis"
            />
          </Helmet>
          <Ptosis />
        </Route>
        <Route path="/procedures/eyelid-and-lacrimal-surgery/tumeurs-palpebrales">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/eyelid-and-lacrimal-surgery/tumeurs-palpebrales"
            />
          </Helmet>
          <TumeurPalpebrales />
        </Route>
        <Route path="/procedures/eyelid-and-lacrimal-surgery/intubation">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/eyelid-and-lacrimal-surgery/intubation"
            />
          </Helmet>
          <Intubation />
        </Route>
        <Route path="/procedures/eyelid-and-lacrimal-surgery/dacryocysthorhinostomie">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/eyelid-and-lacrimal-surgery/dacryocysthorhinostomie"
            />
          </Helmet>
          <Dacryocysthorhinostomie />
        </Route>
        <Route path="/procedures/eyelid-and-lacrimal-surgery/larmoiement">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/eyelid-and-lacrimal-surgery/larmoiement"
            />
          </Helmet>
          <Larmoiement />
        </Route>
        <Route path="/procedures/eyelid-and-lacrimal-surgery/syndrome-sec">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/eyelid-and-lacrimal-surgery/syndrome-sec"
            />
          </Helmet>
          <SyndromeSec />
        </Route>
        <Route path="/procedures/eyelid-and-lacrimal-surgery/malposition-palpebrale-entropion">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/eyelid-and-lacrimal-surgery/malposition-palpebrale-entropion"
            />
          </Helmet>
          <MalpositionPalpébraleEntropion />
        </Route>
        <Route path="/procedures/eyelid-and-lacrimal-surgery/plaies-des-paupieres-canalicules">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/eyelid-and-lacrimal-surgery/plaies-des-paupieres-canalicules"
            />
          </Helmet>
          <PaupièresCanalicules />
        </Route>
        <Route path="/procedures/orbital-and-cavity-surgery" exact>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/orbital-and-cavity-surgery"
            />
          </Helmet>
          <Orbital />
        </Route>
        <Route path="/procedures/orbital-and-cavity-surgery/enucleation-evisceration-prothese">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/orbital-and-cavity-surgery/enucleation-evisceration-prothese"
            />
          </Helmet>
          <EnucléationEviscerationProthese />
        </Route>
        <Route path="/procedures/orbital-and-cavity-surgery/inflammation-et-infection-orbitaire">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/orbital-and-cavity-surgery/inflammation-et-infection-orbitaire"
            />
          </Helmet>
          <InflammationEtInfectionOrbitaire />
        </Route>
        <Route path="/procedures/orbital-and-cavity-surgery/malformation-vasculaire-orbitaire">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/orbital-and-cavity-surgery/malformation-vasculaire-orbitaire"
            />
          </Helmet>
          <MalformationVasculaireOrbitaire />
        </Route>
        <Route path="/procedures/orbital-and-cavity-surgery/oeil-thyroide-et-traitement">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/orbital-and-cavity-surgery/oeil-thyroide-et-traitement"
            />
          </Helmet>
          <OeilThyroideEtTraitement />
        </Route>
        <Route path="/procedures/orbital-and-cavity-surgery/tumeur-orbitaire">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/orbital-and-cavity-surgery/tumeur-orbitaire"
            />
          </Helmet>
          <TumeurOrbitaire />
        </Route>
        <Route path="/procedures/cosmetic-surgery-and-injections" exact>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/cosmetic-surgery-and-injections"
            />
          </Helmet>
          <Cosmetic />
        </Route>
        <Route path="/procedures/cosmetic-surgery-and-injections/blepharoplastie-inferieure">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/cosmetic-surgery-and-injections/blepharoplastie-inferieure"
            />
          </Helmet>
          <BlepharoplastieInferieure />
        </Route>
        <Route path="/procedures/cosmetic-surgery-and-injections/blepharoplastie-superieure">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/cosmetic-surgery-and-injections/blepharoplastie-superieure"
            />
          </Helmet>
          <BlepharoplastieSuperieure />
        </Route>
        <Route path="/procedures/cosmetic-surgery-and-injections/botox">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/cosmetic-surgery-and-injections/botox"
            />
          </Helmet>
          <Botox />
        </Route>
        <Route path="/procedures/cosmetic-surgery-and-injections/cernes">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/cosmetic-surgery-and-injections/cernes"
            />
          </Helmet>
          <Cernes />
        </Route>
        <Route path="/procedures/cosmetic-surgery-and-injections/ptosis-et-bleph">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Procedures</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/procedures/cosmetic-surgery-and-injections/ptosis-et-bleph"
            />
          </Helmet>
          <PtosisEtBleph />
        </Route>
        <Route path="/about">
          <Helmet>
            <meta charSet="utf-8" />
            <title>About Doctor Ben Said Ahmed</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link rel="canonical" href="https://drbensaid.com/about/" />
          </Helmet>
          <About />
        </Route>
        <Route path="/contact">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Contact</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link rel="canonical" href="https://drbensaid.com/contact" />
          </Helmet>
          <Contact />
        </Route>
        <Route path="/media" exact>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link rel="canonical" href="https://drbensaid.com/media" />
          </Helmet>
          <Media />
        </Route>
        <Route path="/media/eyelid-and-lacrimal-surgery" exact>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/eyelid-and-lacrimal-surgery"
            />
          </Helmet>
          <EyelidM />
        </Route>
        <Route path="/media/eyelid-and-lacrimal-surgery/ectropion">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/eyelid-and-lacrimal-surgery/ectropion"
            />
          </Helmet>
          <EctropionM />
        </Route>
        <Route path="/media/eyelid-and-lacrimal-surgery/ptosis">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/eyelid-and-lacrimal-surgery/ptosis"
            />
          </Helmet>
          <PtosisM />
        </Route>
        <Route path="/media/eyelid-and-lacrimal-surgery/tumeurs-palpebrales">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/eyelid-and-lacrimal-surgery/tumeurs-palpebrales"
            />
          </Helmet>
          <TumeurPalpebralesM />
        </Route>
        <Route path="/media/eyelid-and-lacrimal-surgery/intubation">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/eyelid-and-lacrimal-surgery/intubation"
            />
          </Helmet>
          <IntubationM />
        </Route>
        <Route path="/media/eyelid-and-lacrimal-surgery/dacryocysthorhinostomie">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/eyelid-and-lacrimal-surgery/dacryocysthorhinostomie"
            />
          </Helmet>
          <DacryocysthorhinostomieM />
        </Route>
        <Route path="/media/eyelid-and-lacrimal-surgery/larmoiement">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/eyelid-and-lacrimal-surgery/larmoiement"
            />
          </Helmet>
          <LarmoiementM />
        </Route>
        <Route path="/media/eyelid-and-lacrimal-surgery/syndrome-sec">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/eyelid-and-lacrimal-surgery/syndrome-sec"
            />
          </Helmet>
          <SyndromeSecM />
        </Route>
        <Route path="/media/eyelid-and-lacrimal-surgery/malposition-palpebrale-entropion">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/eyelid-and-lacrimal-surgery/malposition-palpebrale-entropion"
            />
          </Helmet>
          <MalpositionPalpébraleEntropionM />
        </Route>
        <Route path="/media/eyelid-and-lacrimal-surgery/plaies-des-paupieres-canalicules">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/eyelid-and-lacrimal-surgery/plaies-des-paupieres-canalicules"
            />
          </Helmet>
          <PaupièresCanaliculesM />
        </Route>
        <Route path="/media/orbital-and-cavity-surgery" exact>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/orbital-and-cavity-surgery"
            />
          </Helmet>
          <OrbitalM />
        </Route>
        <Route path="/media/orbital-and-cavity-surgery/enucleation-evisceration-prothese">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/orbital-and-cavity-surgery/enucleation-evisceration-prothese"
            />
          </Helmet>
          <EnucléationEviscerationProtheseM />
        </Route>
        <Route path="/media/orbital-and-cavity-surgery/inflammation-et-infection-orbitaire">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/orbital-and-cavity-surgery/inflammation-et-infection-orbitaire"
            />
          </Helmet>
          <InflammationEtInfectionOrbitaireM />
        </Route>
        <Route path="/media/orbital-and-cavity-surgery/malformation-vasculaire-orbitaire">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/orbital-and-cavity-surgery/malformation-vasculaire-orbitaire"
            />
          </Helmet>
          <MalformationVasculaireOrbitaireM />
        </Route>
        <Route path="/media/orbital-and-cavity-surgery/oeil-thyroide-et-traitement">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/orbital-and-cavity-surgery/oeil-thyroide-et-traitement"
            />
          </Helmet>
          <OeilThyroideEtTraitementM />
        </Route>
        <Route path="/media/orbital-and-cavity-surgery/tumeur-orbitaire">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/orbital-and-cavity-surgery/tumeur-orbitaire"
            />
          </Helmet>
          <TumeurOrbitaireM />
        </Route>
        <Route path="/media/cosmetic-surgery-and-injections" exact>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/cosmetic-surgery-and-injections"
            />
          </Helmet>
          <CosmeticM />
        </Route>
        <Route path="/media/cosmetic-surgery-and-injections/blepharoplastie-inferieure">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/cosmetic-surgery-and-injections/blepharoplastie-inferieure"
            />
          </Helmet>
          <BlepharoplastieInferieureM />
        </Route>
        <Route path="/media/cosmetic-surgery-and-injections/blepharoplastie-superieure">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/cosmetic-surgery-and-injections/blepharoplastie-superieure"
            />
          </Helmet>
          <BlepharoplastieSuperieureM />
        </Route>
        <Route path="/media/cosmetic-surgery-and-injections/botox">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/cosmetic-surgery-and-injections/botox"
            />
          </Helmet>
          <BotoxM />
        </Route>
        <Route path="/media/cosmetic-surgery-and-injections/cernes">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/cosmetic-surgery-and-injections/cernes"
            />
          </Helmet>
          <CernesM />
        </Route>
        <Route path="/media/cosmetic-surgery-and-injections/ptosis-et-bleph">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Media</title>
            <meta
              name="description"
              content="Le Dr BEN SAID AHMED spécialisée Docteurs En Medecine : Ophtalmologistes. La cabinée de dr BEN SAID AHMED est basée à Tunis."
            />
            <link
              rel="canonical"
              href="https://drbensaid.com/media/cosmetic-surgery-and-injections/ptosis-et-bleph"
            />
          </Helmet>
          <PtosisEtBlephM />
        </Route>
      </Switch>
      <Footer />
    </div>
  )
}
