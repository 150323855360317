import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SidebarPhotos from '../SidebarPhotos'
import '../media.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import prothese1 from '../../../assets/prothese1.jpg'
import prothese2 from '../../../assets/prothese2.jpg'
import prothese3 from '../../../assets/prothese3.jpg'
export default function EnucléationEviscerationProthese() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar'
            ? { textAlign: 'right' }
            : { textAlign: 'center', paddingTop: '20px' }

    return (
        <Container fluid>
            <h1
                style={{ textAlign: 'center', fontSize: '50px' }}
                className="py-4"
            >
                <Trans t={t}>Enucléation éviscération prothèse</Trans>
            </h1>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <SidebarPhotos />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <Row>
                        <Col className="colMedia">
                            <img
                                src={prothese1}
                                alt="prothese cas 1"
                                className="imgMedia"
                            />
                        </Col>
                        <Col
                            style={{
                                backgroundColor: '#FAFAFA',
                            }}
                        >
                            <h4 style={styleAr}>
                                <Trans t={t}>prothese cas 1</Trans>
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="colMedia">
                            <img
                                src={prothese2}
                                alt="prothese cas 2"
                                className="imgMedia"
                            />
                        </Col>
                        <Col>
                            <h4 style={styleAr}>
                                <Trans t={t}>prothese cas 2</Trans>
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="colMedia">
                            <img
                                src={prothese3}
                                alt="prothese cas 3"
                                className="imgMedia"
                            />
                        </Col>
                        <Col>
                            <h4 style={styleAr}>
                                <Trans t={t}>prothese cas 3</Trans>
                            </h4>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
