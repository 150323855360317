import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Accuiel from '../../assets/Accuiel.jpeg'
import { useTranslation, Trans } from 'react-i18next'
import i18n from '../../i18n'
import { history } from '../history'

export default function About() {
  const { t } = useTranslation()
  return (
    <div>
      <Row
        className="mb-3"
        style={
          i18n.language === 'ar'
            ? {
                flexDirection: 'row-reverse',
              }
            : { paddingLeft: 'unset' }
        }
      >
        <Col
          xs="12"
          md="4"
          style={{
            padding: 'unset',
            width: '398px',
            height: '398px',
          }}
        >
          <img
            src={Accuiel}
            alt="about dr ahmed ben said"
            style={{
              width: '100%',
              height: '100%',
              paddingLeft: 'unset',
              objectFit: 'cover',
              objectPosition: '0 0',
            }}
          />
        </Col>
        <Col
          className="pt-3 pl-4"
          xs="12"
          md="8"
          style={
            i18n.language === 'ar'
              ? {
                  textAlign: 'right',
                  alignItems: 'center',
                  display: 'flex',
                }
              : { alignItems: 'center', display: 'flex' }
          }
        >
          <p style={{ paddingLeft: '11px' }}>
            <h2 style={{ fontFamily: 'Elsie', fontSize: '34px' }}>
              <Trans t={t}>About Doctor Ahmed ben said</Trans>
            </h2>

            <p>
              <Trans t={t}>
                Dr Ben said est doublement diplômé en ophtalmologie et en
                chirurgie oculoplastique, réparatrice et esthétique de la région
                péri oculaire, palpébrale, orbitaire et lacrymale, ainsi que
                dans le rajeunissement et la chirurgie du regard. Il exerce à la
                clinique ophtalmologique de Tunis, cité el Khadra, Tunis.
              </Trans>
            </p>
            <div style={{ marginTop: '5%' }}>
              <button className="button" onClick={() => history.push('/about')}>
                <Trans t={t}>Learn More</Trans>
              </button>
            </div>
          </p>
        </Col>
      </Row>
    </div>
  )
}
