import React from 'react'
import './Home.css'
import { Container } from 'react-bootstrap'
// import i18n from "../../i18n";
// import { faEnvelope, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";

import CarouselB from './CarouselB'
import About from './About'
import Specializes from './Specializes'
import Testimonials from './Testimonials'

export default function Home() {
  return (
    <div>
      <h1 style={{ display: 'none' }}>Doctor ben said ahmed ophthalmology</h1>
      {/* slider */}
      <CarouselB />
      <Container fluid>
        {/* About */}
        <About />

        {/* Specializes */}
        <Specializes />

        {/* Video */}
        {/* <Row className=" mt-5">
          <Col xs="12" className="title">
            <h2 style={{ fontFamily: 'Elsie', fontSize: '34px' }}>
              <Trans t={t}>Office</Trans>
            </h2>
          </Col>
          <Col xs="12" style={{ textAlign: 'center', padding: 'unset' }}>
            <video controls autoPlay src={Video} style={{ width: '100%' }} />
          </Col>
        </Row> */}

        {/* Testimonials */}
        <Testimonials />
      </Container>
    </div>
  )
}
