import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SidebarPhotos from '../SidebarPhotos'
import '../media.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import E1Avant from '../../../assets/E1Avant.jpg'
import E1Apres from '../../../assets/E1Apres.jpg'
import E2Avant from '../../../assets/E2Avant.jpg'
import E2Apres from '../../../assets/E2Apres.jpg'
import E3Avant from '../../../assets/E3Avant.jpg'
import E3Apres from '../../../assets/E3Apres.jpg'
import E4Avant from '../../../assets/E4Avant.jpg'
import E4Apres from '../../../assets/E4Apres.jpg'
import E5Avant from '../../../assets/E5Avant.jpg'
import E5Apres from '../../../assets/E5Apres.jpg'
import E6Avant from '../../../assets/E6Avant.jpg'
import E6Apres from '../../../assets/E6Apres.jpg'
export default function Ectropion() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar'
            ? { textAlign: 'right' }
            : { textAlign: 'center', paddingTop: '20px' }

    return (
        <Container fluid>
            <h1
                style={{ textAlign: 'center', fontSize: '50px' }}
                className="py-4"
            >
                <Trans t={t}>Ectropion</Trans>
            </h1>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <SidebarPhotos />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <Row>
                        <Col className="colMedia">
                            <img
                                src={E1Avant}
                                alt="Ectropion cas 1 avant"
                                className="imgMedia"
                            />
                            <img
                                src={E1Apres}
                                alt="Ectropion cas 1 apres"
                                className="imgMedia"
                            />
                        </Col>
                        <Col
                            style={{
                                backgroundColor: '#FAFAFA',
                            }}
                        >
                            <h4 style={styleAr}>
                                <Trans t={t}>Ectropion 1er cas</Trans>
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="colMedia">
                            <img
                                src={E2Avant}
                                alt="Ectropion cas 2 avant"
                                className="imgMedia"
                            />
                            <img
                                src={E2Apres}
                                alt="Ectropion cas 2 apres"
                                className="imgMedia"
                            />
                        </Col>
                        <Col>
                            <h4 style={styleAr}>
                                <Trans t={t}>Ectropion 2eme cas</Trans>
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="colMedia">
                            <img
                                src={E3Avant}
                                alt="Ectropion cas 3 avant"
                                className="imgMedia"
                            />
                            <img
                                src={E3Apres}
                                alt="Ectropion cas 3 apres"
                                className="imgMedia"
                            />
                        </Col>
                        <Col
                            style={{
                                backgroundColor: '#FAFAFA',
                            }}
                        >
                            <h4 style={styleAr}>
                                <Trans t={t}>Ectropion 3eme cas</Trans>
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="colMedia">
                            <img
                                src={E4Avant}
                                alt="Ectropion cas 4 avant"
                                className="imgMedia"
                            />
                            <img
                                src={E4Apres}
                                alt="Ectropion cas 4 apres"
                                className="imgMedia"
                            />
                        </Col>
                        <Col>
                            <h4 style={styleAr}>
                                <Trans t={t}>Ectropion 4eme cas</Trans>
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="colMedia">
                            <img
                                src={E5Avant}
                                alt="Ectropion cas 5 avant"
                                className="imgMedia"
                            />
                            <img
                                src={E5Apres}
                                alt="Ectropion cas 5 apres"
                                className="imgMedia"
                            />
                        </Col>
                        <Col
                            style={{
                                backgroundColor: '#FAFAFA',
                            }}
                        >
                            <h4 style={styleAr}>
                                <Trans t={t}>Ectropion 5eme cas</Trans>
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="colMedia">
                            <img
                                src={E6Avant}
                                alt="Ectropion cas 6 avant"
                                className="imgMedia"
                            />
                            <img
                                src={E6Apres}
                                alt="Ectropion cas 6 apres"
                                className="imgMedia"
                            />
                        </Col>
                        <Col>
                            <h4 style={styleAr}>
                                <Trans t={t}>Ectropion 6eme cas</Trans>
                            </h4>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
