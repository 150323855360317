import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function Larmoiement() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }

    return (
        <Container fluid>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                ></Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h1 style={styleAr} className="py-4">
                        <Trans t={t}>Larmoiement des yeux</Trans>
                    </h1>
                </Col>
            </Row>

            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h4 style={styleAr}>
                        <Trans t={t}>Definition</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Il s'agit d'un larmoiement excessif, Les
                                    larmes sont le résultat d’un équilibre entre
                                    une sécrétion (production) et une excrétion
                                    (drainage)
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Une production excessive de larmes peut
                                    provoquer une sensation de larmoiement ou
                                    provoquer une coulée de larmes sur la joue.
                                    D’autres symptômes comme une irritation ou
                                    une douleur oculaire peuvent se manifester
                                    en fonction de la cause.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La plupart des larmes sont sécrétées par les
                                    glandes lacrymales situées au-dessus de la
                                    partie externe de la paupière supérieure.
                                    Les larmes coulent dans l’œil et ressortent
                                    par de petits orifices dans les coins
                                    internes des paupières près du nez (les
                                    points lacrymaux supérieur et inférieur) et
                                    se dirigent vers un petit conduit (le
                                    canalicule). Ensuite, elles atteignent le
                                    sac lacrymal et passent par le canal
                                    lacrymonasal jusqu’au nez.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Une obstruction à n’importe quel niveau de
                                    la voie d’écoulement des larmes peut
                                    entraîner un larmoiement.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Une obstruction au niveau du canal
                                    lacrymo-nasal au dessous du sac peut
                                    conduire à une infection du sac lacrymal,
                                    due à une stagnation des larmes au niveau du
                                    sac (dacryocystite). Cette infection peut
                                    parfois se propager aux tissus situés autour
                                    de l’œil (cellulite périorbitaire).
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Les causes des larmes</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Une production accrue de larmes ou une
                                    obstruction des voies d’écoulement des
                                    larmes peuvent provoquer le larmoiement des
                                    yeux.
                                </Trans>
                                <strong>
                                    <Trans t={t}>Les causes courantes</Trans>
                                </strong>
                                <Trans t={t}> sont</Trans>
                                <ul style={{ marginTop: '8px' }}>
                                    <li
                                        className="paraProcedure"
                                        style={styleAr}
                                    >
                                        <Trans t={t}>
                                            Infection des voies respiratoires
                                            supérieures
                                        </Trans>
                                    </li>
                                    <li
                                        className="paraProcedure"
                                        style={styleAr}
                                    >
                                        <Trans t={t}>
                                            Allergies touchant le nez (rhinite
                                            allergique), les yeux (conjonctivite
                                            allergique) ou les deux.
                                        </Trans>
                                    </li>
                                </ul>
                                <strong>
                                    <Trans t={t}>D'autres causes</Trans>
                                </strong>
                                <Trans t={t}>incluent</Trans>
                                <ul style={{ marginTop: '8px' }}>
                                    <li
                                        className="paraProcedure"
                                        style={styleAr}
                                    >
                                        <strong>
                                            <Trans t={t}>
                                                La sécheresse oculaire
                                            </Trans>
                                        </strong>
                                        <Trans t={t}>
                                            {' '}
                                            aussi étrange que cela puisse
                                            paraitre, la surface sèche de l’œil
                                            s’irrite, les glandes lacrymales
                                            produisent des larmes « réflexes »
                                            et ainsi, de manière surprenante,
                                            les yeux secs peuvent produire des
                                            larmoiements (voir chapitre
                                            sécheresse). Certaines tâches,
                                            telles que lire ou regarder la
                                            télévision diminuent le clignement
                                            par une hyper concentration
                                            provoquant ainsi une sécheresse
                                            oculaire.
                                        </Trans>
                                    </li>
                                    <li
                                        className="paraProcedure"
                                        style={styleAr}
                                    >
                                        <strong>
                                            <Trans t={t}>Les cils</Trans>
                                        </strong>
                                        <Trans t={t}>
                                            {' '}
                                            retournés vers l’intérieur qui
                                            frottent contre le globe oculaire
                                            (trichiasis)
                                        </Trans>
                                    </li>
                                    <li
                                        className="paraProcedure"
                                        style={styleAr}
                                    >
                                        <Trans t={t}>
                                            Paupière retournée vers l’extérieur
                                            (ectropion) qui déplace le point
                                            lacrymal de sa position normale en
                                            contact du globe vers l’extérieur.
                                            ainsi les larmes ne peuvent plus
                                            passer par le point lacrymal et
                                            s’écouler vers le canal
                                            lacrymo-nasal et le nez.
                                        </Trans>
                                    </li>
                                    <li
                                        className="paraProcedure"
                                        style={styleAr}
                                    >
                                        <Trans t={t}>
                                            Rétrécissement des conduits
                                            lacrymaux liés à l’âge, appelé
                                            dacryosténose acquise
                                        </Trans>
                                    </li>
                                    <li
                                        className="paraProcedure"
                                        style={styleAr}
                                    >
                                        <Trans t={t}>
                                            Des infections chroniques du sac
                                            lacrymal, appelées dacryocystite.
                                        </Trans>
                                    </li>
                                    <li
                                        className="paraProcedure"
                                        style={styleAr}
                                    >
                                        <Trans t={t}>
                                            Lésion ou irritation cornéenne.
                                        </Trans>
                                    </li>
                                </ul>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Tout trouble causant une irritation de la
                                    cornée (couche transparente située devant
                                    l’iris et la pupille) augmente la production
                                    de larmes. Cependant, la plupart des
                                    personnes atteintes de troubles de la cornée
                                    causant des larmoiements (comme une
                                    égratignure de la cornée ou une plaie, un
                                    corps étranger dans l’œil ou une
                                    inflammation de la cornée) souffrent de
                                    douleurs importantes, de rougeurs et/ou
                                    d’une sensibilité à la lumière, raisons pour
                                    lesquelles ces personnes consultent un
                                    médecin.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
