import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import '../../Procedures/Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function CosmeticM() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : {}

    return (
        <Container fluid style={{ padding: '0 5%', marginBottom: '20px' }}>
            <h1
                style={{ textAlign: 'center', fontSize: '50px' }}
                className="py-4"
            >
                <Trans t={t}>Chirurgie esthétique et injections</Trans>
            </h1>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col md={{ span: 3, order: 1 }} xs={{ span: 12, order: 2 }}>
                    <p className="paraProcedure" style={styleAr}>
                        <a
                            href="/procedures/cosmetic-surgery-and-injections/blepharoplastie-inferieure"
                            className="aProcedure"
                        >
                            <Trans t={t}>Blépharoplastie inférieure</Trans>
                        </a>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <a
                            href="/procedures/cosmetic-surgery-and-injections/blepharoplastie superieure"
                            className="aProcedure"
                        >
                            <Trans t={t}>Blépharoplastie supérieure</Trans>
                        </a>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <a
                            href="/procedures/cosmetic-surgery-and-injections/botox"
                            className="aProcedure"
                        >
                            <Trans t={t}>Botox</Trans>
                        </a>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <a
                            href="/procedures/cosmetic-surgery-and-injections/cernes"
                            className="aProcedure"
                        >
                            <Trans t={t}>Les cernes</Trans>
                        </a>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <a
                            href="/procedures/cosmetic-surgery-and-injections/ptosis-et-bleph"
                            className="aProcedure"
                        >
                            <Trans t={t}>Ptosis et bleph</Trans>
                        </a>
                    </p>
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <Row
                        style={
                            i18n.language === 'ar'
                                ? { flexDirection: 'row-reverse' }
                                : {}
                        }
                    >
                        <Col
                            style={
                                i18n.language === 'ar'
                                    ? {
                                          flexDirection: 'row-reverse',
                                          alignItems: 'center',
                                          display: 'flex',
                                      }
                                    : { display: 'flex', alignItems: 'center' }
                            }
                        >
                            <h4 style={styleAr}>
                                <Trans t={t}>Le regard</Trans>
                            </h4>
                        </Col>
                        <Col
                            style={{
                                flexGrow: 0,
                                whiteSpace: 'nowrap',
                                color: '#bd2130',
                            }}
                        >
                            <cite style={styleAr}>
                                <Trans t={t}>
                                    « La vie commence là où commence le regard »
                                </Trans>{' '}
                            </cite>
                            <br />
                            <cite style={styleAr}>
                                <Trans t={t}>
                                    « Les yeux sont les fenêtres de l'âme »
                                </Trans>{' '}
                                »
                            </cite>
                            <br />
                            <cite style={styleAr}>
                                <Trans t={t}>
                                    « Tout vrai regard est un désir »
                                </Trans>{' '}
                                »
                            </cite>
                            <br />
                            <cite style={styleAr}>
                                <Trans t={t}>
                                    « Le regard est le médiateur des cœurs »
                                </Trans>{' '}
                                »
                            </cite>
                        </Col>
                    </Row>

                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            L’harmonie et l’équilibre du regard repose sur un
                            ensemble de détails le front et ses rides, les
                            sourcils bien positionnés et symétriques, une
                            paupière supérieure pleine dont le pli est bien
                            défini et qui n’empiète pas sur les cils, la patte
                            d’oie peu ridée, une paupière inférieure en
                            continuité avec la région périorbitaire inférieure,
                            la joue et le visage.
                        </Trans>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            Est-ce que le regard parfait existe ?
                        </Trans>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            Chaque personne a son propre regard singulier et
                            personnel qui fait son charme et sa beauté. Notre
                            but principal est de donner un coup d’éclat au
                            regard sans le changer et de réparer les
                            imperfections apparues avec l’âge sans le modifier.
                        </Trans>
                    </p>

                    <h4 style={styleAr}>
                        <Trans t={t}>Les demandes en esthétique</Trans>
                    </h4>
                    <p className="paraProcedure" style={styleAr}>
                        <em>
                            <Trans t={t}>
                                « Les paupières tombent, s’alourdissent, la peau
                                se relâche et se fripe, les poches se forment,
                                les yeux se creusent, les cernes sont plus
                                sombres…
                            </Trans>
                        </em>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <em>
                            <Trans t={t}>
                                J’ai un regard endormi .J’ai un regard sévère.
                                Mon regard est triste. Mon regard est tragique …
                            </Trans>
                        </em>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <em>
                            <Trans t={t}>
                                La patte d’oie, la ride de lion, les rides du
                                front et toutes les rides d’expression et du
                                visage.
                            </Trans>
                        </em>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <em>
                            <Trans t={t}>
                                Les lèvres fines, le sillon naso-génien ou les
                                plis d’amertume qui rendent mon visage affaibli.
                                »
                            </Trans>
                        </em>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            L’âge, le vieillissement, l’environnement ou le
                            terrain génétique influencent sur l’évolution et
                            l’expression de votre visage et de votre regard.
                        </Trans>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            L’oculoplasticen est spécialiste dans la chirurgie
                            péri oculaire et palpébrale, il connait le mieux la
                            sémiologie du regard, analyser ses défaut, les
                            petits détails et détecter le moindre changement. Il
                            a une maitrise parfaite de cette région du visage,
                            de son anatomie et de toutes les techniques
                            chirurgicales et microchirurgicales utilisées pour
                            réparer, rajeunir et améliorer votre regard.
                        </Trans>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            Les paupières protègent vos yeux et la santé de
                            ceux-ci reste notre priorité.
                        </Trans>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            Dr Ben said est doublement diplômé en ophtalmologie
                            et en chirurgie réparatrice et esthétique péri
                            oculaire. Il est donc le mieux placé pour vous
                            prendre en charge et garantir toute la sécurité en
                            évitant les complications liées à la chirurgie.
                        </Trans>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            Les traitements cosmétiques non chirurgicaux peuvent
                            être proposés pour les patients qui n’ont pas besoin
                            de chirurgie ou qui hésitent à subir une
                            intervention chirurgicale ou qui présentent une
                            contre indication à la chirurgie. Ils peuvent
                            également être utilisés avec la chirurgie pour un
                            résultat associant plusieurs facteurs et dans des
                            endroits différents du visage.
                        </Trans>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            Nous déterminerons les meilleurs traitements en
                            fonction de vos besoins et de votre attente.
                            Plusieurs procédures sur une large gamme de produit
                            sont proposées comme le comblement par l’acide
                            hyaluronique ( Restylane, Perlane, Juvederm,
                            Radiesse...), l’injection de toxine botulinique
                            (Botox, Dysport, ) , les peelings chimiques , le
                            laser CO2 et autres.
                        </Trans>
                    </p>
                    <p className="paraProcedure" style={styleAr}>
                        <Trans t={t}>
                            Notre objectif est l’équilibre, l’harmonie et une
                            apparence naturelle de votre visage pour vous
                            satisfaire et répondre à vos attentes.
                        </Trans>
                    </p>
                </Col>
            </Row>
        </Container>
    )
}
