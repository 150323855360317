import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Login from '../../Login'
import MediaAdmin from '../../MediaAdmin'

export default function LayoutPublic() {
    return (
        <div>
            <Switch>
                <Route path="/admin" component={Login} exact />
                <Route path="/admin/media" component={MediaAdmin} />
            </Switch>
        </div>
    )
}
