import React, { useState } from 'react'
import './Login.css'
import { Row, Col, Form, Button, Container } from 'react-bootstrap'
import { history } from '../history'

import { LoginAdmin } from '../../api/auth'

export default function Login() {
  if (sessionStorage.getItem('admin-token')) history.push('/admin/media')
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')

  const submitForm = async (e: any) => {
    e.preventDefault()
    const token = await LoginAdmin(login, password)
    if (
      token !== 'error' &&
      token !== 'Password is Woring!' &&
      token !== 'Login is Woring!'
    ) {
      sessionStorage.setItem('admin-token', token)
      history.push('/admin/media')
    } else {
      setPassword('')
    }
  }

  return (
    <Container
      fluid
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Row>
        <Col>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Login</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter login"
                value={login}
                onChange={(e: any) => setLogin(e.currentTarget.value)}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e: any) => setPassword(e.currentTarget.value)}
              />
            </Form.Group>
            <Col style={{ textAlign: 'center' }}>
              <Button
                variant="primary"
                type="submit"
                style={{
                  backgroundColor: '#4FEA9E',
                  border: 'none',
                }}
                onClick={submitForm}
              >
                Submit
              </Button>
            </Col>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
