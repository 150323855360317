import React from 'react'
import { Row, Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faYoutube,
  faInstagram,
  faSkype,
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import { useTranslation, Trans } from 'react-i18next'

export default function Menu() {
  const { t } = useTranslation()

  const styleCol: React.CSSProperties = { padding: '0 10px 0 0', flexGrow: 0 }
  return (
    <Row
      className="pt-3"
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'baseline',
        justifyContent: 'flex-end',
      }}
    >
      <Col style={styleCol}>
        <FontAwesomeIcon
          icon={faFacebookF}
          style={{ color: 'blue', cursor: 'pointer' }}
        />
      </Col>
      <Col style={styleCol}>
        <FontAwesomeIcon
          icon={faYoutube}
          style={{ color: 'red', cursor: 'pointer' }}
        />
      </Col>
      <Col>
        <a
          href="https://www.instagram.com/drbensaidahmed/?hl=fr"
          style={styleCol}
        >
          <FontAwesomeIcon
            icon={faInstagram}
            style={{ color: '#ae3b98', cursor: 'pointer' }}
          />
        </a>
      </Col>

      <Col style={styleCol}>
        <FontAwesomeIcon
          icon={faSkype}
          style={{ color: '#47D1F3', cursor: 'pointer' }}
        />
      </Col>
      <Col style={styleCol}>|</Col>
      <Col
        style={{
          fontFamily: 'Raleway',
          color: '#442F4E',
          fontWeight: 600,
          whiteSpace: 'nowrap',
          flexGrow: 0,
        }}
      >
        <Trans t={t}>Consultations Available</Trans>
      </Col>
      <Col style={styleCol}>|</Col>
      <Col style={{ flexGrow: 0, paddingRight: '9px' }}>
        <FontAwesomeIcon
          style={{
            fontSize: '20px',
            color: '#47D1F3',
            position: 'absolute',
            bottom: '-5px',
          }}
          icon={faEnvelope}
        />
      </Col>
      <Col
        style={{
          fontFamily: 'Raleway',
          textDecoration: 'none',
          color: '#442f4e',
          fontWeight: 600,
          flexGrow: 0,
          whiteSpace: 'nowrap',
        }}
      >
        contact@drbensaid.com
      </Col>
      <Col style={styleCol}>|</Col>
      <Col
        style={{
          color: '#442f4e',
          fontWeight: 600,
          whiteSpace: 'nowrap',
          flexGrow: 0,
        }}
      >
        <FontAwesomeIcon
          style={{ fontSize: '23px', color: '#47D1F3', marginRight: '6px' }}
          icon={faPhoneVolume}
        />
        +216 12 123 123
      </Col>
    </Row>
  )
}
