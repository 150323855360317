import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function Ectropion() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }

    return (
        <Container fluid>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                ></Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h1 style={styleAr} className="py-4">
                        <Trans t={t}>Ectropion</Trans>
                    </h1>
                </Col>
            </Row>

            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h4 style={styleAr}>
                        <Trans t={t}>Definition</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    L’ectropion signifie que le bord palpébral
                                    est retourné ou éversé vers l'extérieur et
                                    il n’est plus en contact avec le globe
                                    oculaire. Il touche la paupière inférieure
                                    dans la majorité des cas.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Les paupières supérieure et inférieure
                                    protègent l’œil contre l’environnement
                                    extérieur. Toute exposition du globe
                                    oculaire suite à un ectropion peut provoquer
                                    des problémes oculaires.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La cause la plus fréquente de l’ectropion
                                    est le relâchement et la laxité de la
                                    paupière inférieure qui est due à l'âge,
                                    c’est l’ectropion involutionel ou sénile.
                                    Les autres causes comprennent les cicatrices
                                    post traumatique ou chirurgicale, la brulure
                                    cutanée, la paralysie faciale ou mécanique
                                    (tumorales ou autres).
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Symptômes de l’ectropion</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Devant un ectropion la protection du globe
                                    oculaire diminue et ceci dépend du degré de
                                    l’ectropion.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Quand l’œil est exposé à l’air ambiant sans
                                    la protection totale de la paupière
                                    inférieure, le globe devient irrité, rouge
                                    avec un larmoiement chronique.Dans des cas
                                    très avancés on peut avoir une ulcération,
                                    une infection ou un abcès de cornée, une
                                    cicatrice ou une opacité cornéenne
                                    chronique.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    La partie palpébrale interne de la paupière
                                    inférieure exposée peut changer de structure
                                    et d’aspect, elle devient kératinisée comme
                                    la peau. Ceci peut interférer avec le point
                                    lacrymal et le système de drainage des
                                    larmes.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>
                            Comment réparer l'ectropion palpébral ?
                        </Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Le traitement de l'ectropion palpébral est
                                    chirurgical. La technique chirurgicale
                                    classique pour réparer l’ectropion
                                    involutionel sénile est la latéral tarsal
                                    strip ou la technique de bandelette tarsale
                                    associée à une canthoplastie et une
                                </Trans>
                                <Trans t={t}> canthopéxie</Trans>
                                <Trans t={t}>
                                    c'est à dire reformé le coin ou l’angle
                                    externe des 2 paupières.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Cette technique consiste à repositionner la
                                    paupière en la refixant sur le périoste
                                    orbitaire tout en réparant le relâchement et
                                    la laxité palpébrale (C’est comme une
                                    ceinture d’un pantalon qui est large et sa
                                    longueur est plus grande que la taille d’une
                                    personne, on coupe l’excédant est la refixe
                                    sur la boucle)
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    D’autres techniques chirurgicales sont
                                    utilisées pour traiter l’ectropion et
                                    surtout la cause, comme la greffe de peau et
                                    les autres moyens de réparation palpébrale
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Vous pouvez comprendre par différente
                                    illustrations.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
