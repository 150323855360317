import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SidebarPhotos from '../SidebarPhotos'
import '../../Procedures/Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import blephptosisAvant from '../../../assets/blephptosisAvant.jpg'
import blephptosisApres from '../../../assets/blephptosisApres.jpg'
export default function PtosisEtBlephM() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar'
            ? { textAlign: 'right' }
            : { textAlign: 'center', paddingTop: '20px' }

    return (
        <Container fluid>
            <h1
                style={{ textAlign: 'center', fontSize: '50px' }}
                className="py-4"
            >
                <Trans t={t}>Ptosis et bleph</Trans>
            </h1>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <SidebarPhotos />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <Row>
                        <Col className="colMedia">
                            <img
                                src={blephptosisAvant}
                                alt="Ptosis et bleph cas 1 avant"
                                className="imgMedia"
                            />
                            <img
                                src={blephptosisApres}
                                alt="Ptosis et bleph cas 1 apres"
                                className="imgMedia"
                            />
                        </Col>
                        <Col
                            style={{
                                backgroundColor: '#FAFAFA',
                            }}
                        >
                            <h4 style={styleAr}>
                                <Trans t={t}>Ptosis et bleph</Trans>
                            </h4>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
