import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import '../Procedures.css'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
export default function PaupièresCanalicules() {
    const { t } = useTranslation()

    const styleAr: React.CSSProperties =
        i18n.language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }

    return (
        <Container fluid>
            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                ></Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h1 style={styleAr} className="py-4">
                        <Trans t={t}>Plaies des paupières canalicules</Trans>
                    </h1>
                </Col>
            </Row>

            <Row
                style={
                    i18n.language === 'ar'
                        ? { flexDirection: 'row-reverse' }
                        : {}
                }
            >
                <Col
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    style={{ paddingLeft: '0px' }}
                >
                    <Sidebar />
                </Col>
                <Col
                    md={{ span: 9, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    style={{ marginBottom: '30px' }}
                >
                    <h4 style={styleAr}>
                        <Trans t={t}>Definition</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Un accident domestique de travail ou de la
                                    route peut provoquer une plaie de paupière
                                    qui peut être cutanée et superficielle
                                    facilement réparable sous anesthésie locale.
                                    D’autres plaies plus complexes et plus
                                    profondes touchent les couches profondes
                                    comme le muscle orbiculaire, le releveur de
                                    la paupière (provoquant un ptosis), le
                                    tarse, les ligaments ou même la conjonctive.
                                    Il est important qu’un chirurgien
                                    spécialiste des paupières qui connait bien
                                    l’anatomie et l’esthétique palpébrale répare
                                    cette plaie selon les règles, ceci doit se
                                    faire plan par plan pour rétablir le
                                    meilleur résultat esthétique et fonctionnel.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                    <h4 style={styleAr}>
                        <Trans t={t}>Plaies des canalicules lacrymales</Trans>
                    </h4>
                    <Row>
                        <Col>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    Quand le traumatisme est interne, proche du
                                    nez. Il peut toucher les canalicules que ce
                                    soit supérieure ou inférieure et provoquer
                                    une plaie des voie excrétrices lacrymale.
                                    Quand cette plaie est ignorée ou mal opérée,
                                    le patient sera gêné et il aura
                                </Trans>{' '}
                                <strong>
                                    <Trans t={t}>
                                        un larmoiement chronique.
                                    </Trans>
                                </strong>{' '}
                                <Trans t={t}>
                                    D’où l’intérêt d’une réparation bien faite
                                    par un spécialiste des paupières pour éviter
                                    ce genre de complication.
                                </Trans>
                            </p>
                            <p className="paraProcedure" style={styleAr}>
                                <Trans t={t}>
                                    De préférence la chirurgie doit se faire
                                    dans les 48 heures pour trouver facilement
                                    le canalicule et le réparer. Cette chirurgie
                                    se réalise souvent sous anesthésie générale,
                                    dans la majorité des cas, on utilise une
                                    sonde en silicone qui va être posée dans le
                                    canalicule et qui préservera le conduit
                                    lacrymale. Cette sonde sera enlevée 4 à 6
                                    mois après l’intervention.
                                </Trans>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
